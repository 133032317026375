import { useEffect, useState } from "react";
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useCompaniesInit, useCompaniesList } from "../Persistence/CompaniesContext";
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { InputNumberChecked } from "../Components/InputNumberChecked";
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";

export const employmentObligationOptions = [{ display: 'Ja', value: 1 }, { display: 'Nein', value: 0 }, { display: 'Berechnet', value: 2 }, { display: 'Offen', value: 3 }]
export const emptyCompany = { id: null, version: 1, active: 1, name: '', phone: '', mobile: '', fax: '', mail: '', employees: 0, employeesSbM: 0, employmentObligation: 3, chamber: '', chamberNo: '', regionId: '', region: '', addresses: [], contactPersons: [], updateUser: null, companyUsers: [], companyComments: null, description: '', existingCompany: false, companyCheck: false }

export const CompanyForm = ({ formik, handleSelectExistingCompany, readOnly, showTrash }) => {

	const regionsList = useRegionsList();
	const regionsSelectionList = useRegionsSelectionList();
	const useCompanyList = useCompaniesList();
	const companiesInit = useCompaniesInit();
	const [mounted, setMounted] = useState(false)

	const [regionsSelection, setRegionsSelection] = useState([]);
	const [companiesList, setCompaniesList] = useState([])

	useEffect(() => {
		if (useCompanyList) {
			setCompaniesList(useCompanyList.filter((entry) => entry.active))
		}
		setMounted(true)
	}, [useCompanyList])

	const createRegionSelection = () => {
		/** prüfe ob die Region die bisher bei dem Unternehmen gesetzt war noch in der Liste ist.
		 * Wenn nicht -> hinzufügen
		 */
		const regionSelection = regionsSelectionList ? JSON.parse(JSON.stringify(regionsSelectionList)) : [];
		if (formik.values.regionId) {
			const region = regionsList.find(entry => entry.id === formik.values.regionId)
			if (region && !region.active) {
				regionSelection.push(region);
			}
		}
		if (!formik.values.id && !formik.values.regionId && regionSelection && regionSelection.length === 1) {
			formik.setFieldValue('regionId', regionSelection[0].id)
		}
		setRegionsSelection(regionSelection)
	}

	useEffect(() => {
		createRegionSelection();
		companiesInit();
	}, [])

	const handleCompanyNameChange = (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			handleSelectExistingCompany(e.value.id)
		} else {
			formik.setFieldValue('name', e.value)
		}
	}
	const handleCompanyNameBlur = (e) => {
		const existingCompany = useCompanyList.find(company => company.name.toUpperCase().trim() === e.target.value.toUpperCase().trim())
		formik.setValues({
			...formik.values, existingCompany: (existingCompany && formik.values.id !== existingCompany.id),
			companyCheck: false
		})
	}

	return (
		<div>{!formik ? '' :
			< div className='col p-0 m-0' >
				<div className="formgrid grid">
					{formik.values && !formik.values.id ? <>
						<AutoCompleteChangesChecked dropdown={true} label={'Name des Unternehmens'} id={'name'} itemLabel={'name'} formik={formik} forceSelection={false} suggestionsList={companiesList}
							helptextcolor='#F4A261' helptext='Ein Unternehmen mit diesem Namen ist der Datenbank nicht bekannt. Beim Speichern wird das Unternehmen mit den eingegebenen Daten in der Datenbank eingetragen.'
							onBlur={handleCompanyNameBlur}
							onFocus={() => formik.setFieldValue('companyCheck', true)}
							handleChange={handleCompanyNameChange} />
					</> : <>
						<InputChangesChecked id='name' type="text"
							formik={formik} handleBlur={handleCompanyNameBlur}
							helptextcolor='#24A347'
							helptext_old='Sie haben ein bekanntes Unternehmen aus der Datenbank ausgewählt. Es wird kein neues Unternehmen erstellt.'
							label='Name des Unternehmens' />
						{showTrash && (formik.values && formik.values.id) ? <div className="flex h-4rem mr-4">
							<div className="flex align-items-end w-full" onClick={() => { handleSelectExistingCompany(null); }}>
								<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.BACKSPACE} size={ENUM_AWESOMEICON_SIZE.XL2} />
							</div>
						</div> : ''}
					</>}

				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='phone' type="text" formik={formik} label='Telefon' disabled={readOnly} />
					<InputChangesChecked id='fax' type="text" formik={formik} label='Fax' disabled={readOnly} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='mobile' label='Mobil' type="text" formik={formik} disabled={readOnly} />
					<InputChangesChecked id='mail' label='E-Mail' type="text" formik={formik} disabled={readOnly} />
				</div>

				<div className="formgrid grid">
					<InputNumberChecked className='w-5rem' id='employees' disabled={readOnly} label='Mitarbeiter*innen' type="number" formik={formik} min={0} showButtons={true} />
					<InputNumberChecked className='w-5rem' id='employeesSbM' disabled={readOnly} label='SbM' type="number" formik={formik} min={0} showButtons={true} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='chamber' label='Kammer' type="text" formik={formik} disabled={readOnly} />
					<InputChangesChecked id='chamberNo' label='Betriebsnummer' type="text" formik={formik} disabled={readOnly} />
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked options={regionsSelection} disabled={readOnly} formik={formik} id='regionId' optionValue='id' itemLabel='name' label='EAA Region' onChange={createRegionSelection} />
					<DropdownChangesChecked options={employmentObligationOptions} disabled={readOnly} formik={formik} id='employmentObligation' optionValue='value' itemLabel='display' label='Beschäftigungspflicht' />
				</div>
				<div className="formgrid grid">
					{!mounted ? '' : <EditorChangesChecked id='description' value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Unternehmensbeschreibung' disabled={readOnly} />}
				</div>
			</div>
		}</div>
	)
}

export const validateCompany = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name des Unternehmens ist ein Pflichtfeld';
	}
	if (data.companyCheck) {
		errors.name = 'Name des Unternehmens wird geprüft';
	} else if (data.existingCompany) {
		errors.name = 'Unternehmen mit diesem Namen bereits vorhanden.';
	}
	if (!data.regionId) {
		errors.regionId = 'Region ist ein Pflichtfeld';
	}
	if (!data.employees === '0' && !data.employees) {
		errors.employees = 'Mitarbeiter*innen ist ein Pflichtfeld';
	}
	if (!data.employeesSbM === '0' && !data.employeesSbM) {
		errors.employeesSbM = 'SbM ist ein Pflichtfeld';
	} else if (data.employeesSbM > data.employees) {
		errors.employeesSbM = 'SbM muss kleiner als Anzahl Mitarbeiter*innen sein!';
	}
	return errors;
}