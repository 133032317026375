import React, { useContext, useState, useEffect } from "react";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { findData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { fetchDataWithPagination } from "../snippets";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'RequestDistributionsAssignedProvider';
const servicename = ENUM_SERVICES.REQUESTDISTRIBUTIONS;
const RequestDistributionsAssignedContext = React.createContext();

export function useRequestDistributionsAssignedList() {
	return useContext(RequestDistributionsAssignedContext);
}

export function RequestDistributionsAssignedProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' mount');
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			if (listItemChanged.status !== 5) {
				listItemChanged.removed = true
			}
			logContext(providername, 'info', 'ListItemChanged', listItemChanged)
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			const query = {
				active: 1,
				status: 5,
				$sort: { id: 0 }
			}
			new Promise(() => { fetchDataWithPagination(ENUM_SERVICES.REQUESTDISTRIBUTIONS, setList, query) })
			/* 			findData(servicename, query).then((_list) => {
							setList(_list);
							logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length}`)
						}).catch((error) => { LoggerContext.error("Error " + providername, error) }); */
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<RequestDistributionsAssignedContext.Provider value={list}>
			{children}
		</RequestDistributionsAssignedContext.Provider>
	)
}