import { parseISO } from 'date-fns';
import { FilterService, FilterMatchMode } from 'primereact/api';

FilterService.register("multiIds", (value, filter) => { return !filter ? true : value.filter((element: any) => filter.includes(element.id)).length > 0 });
FilterService.register("ArrayContainsMatchMode", (value, filter) => { return !filter ? true : value.filter((element: any) => filter.includes(element)).length > 0 });

export enum ENUM_DATAVIEWS_CONFIG {
	EAACASES = 'EAACASES',
	COMPANIES = 'COMPANIES',
	PROVIDERACTIVITIES = 'PROVIDERACTIVITIES',
	ASSOCIATEDCONTACTS = 'ASSOCIATEDCONTACTS',
	REQUESTDISTRIBUTIONS_USERMATRIX = 'REQUESTDISTRIBUTIONS_USERMATRIX',
	REQUESTDISTRIBUTIONSARCHIV = 'REQUESTDISTRIBUTIONSARCHIV',
	REQUESTDISTRIBUTIONS = 'REQUESTDISTRIBUTIONS',
	REMINDERS = 'REMINDERS',
	JOBOPENINGS = 'JOBOPENINGS',
	USERADMINISTRATION = 'USERADMINISTRATION',
	CASERQUESTTYPES = 'CASERQUESTTYPES',
	REGIONS = 'REGIONS',
	ORGANIZATIONS = 'ORGANIZATIONS',
	TASKAREAS = 'TASKAREAS',
	OVERVIEWS_ASSOCIATEDCONTACTS = 'OVERVIEWS_ASSOCIATEDCONTACTS',
	OVERVIEWS_CASEEVENTS = 'OVERVIEWS_CASEEVENTS',
	OVERVIEWS_CASEREQUESTS = 'OVERVIEWS_CASEREQUESTS',
	OVERVIEWS_CASEPERSONS = 'OVERVIEWS_CASEPERSONS',
	OVERVIEWS_RELATEDUSERS = 'OVERVIEWS_RELATEDUSERS',
	OVERVIEWS_COMPANYADDRESSES = 'OVERVIEWS_COMPANYADDRESSES',
	OVERVIEWS_COMPANYCONTACTS = 'OVERVIEWS_COMPANYCONTACTS',
	OVERVIEWS_PROVIDERACTIVITIES = 'OVERVIEWS_PROVIDERACTIVITIES',
};

export type DATAVIEW_CONFIG_ENTRY = {
	alias: ENUM_DATAVIEWS_CONFIG,
	headline?: string,
	service: string,
	filtername: string,
	filters: object,
	sort: object,
	pagination: object,
	exportFilename: string,
	exportFunction?: Function
}

export type DATAVIEW_CONFIG_TYPE = {
	[ENUM_DATAVIEWS_CONFIG.EAACASES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.COMPANIES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.PROVIDERACTIVITIES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.ASSOCIATEDCONTACTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONS_USERMATRIX]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONSARCHIV]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REMINDERS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.JOBOPENINGS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.USERADMINISTRATION]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.CASERQUESTTYPES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REGIONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.ORGANIZATIONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.TASKAREAS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES]: DATAVIEW_CONFIG_ENTRY,
}



const DEFAULT_COMPANIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: '' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: '' },
}

const DEFAULT_ASSOCIATEDCONTACTS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'organization': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Träger suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'E-Mail suchen' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_EAACASES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', },
	'status': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'CaseStatusList', filterElement: 'multiTagFilterElement', filterSelectedLabel: 'Status', filterPlaceholder: 'Status wählen', maxSelectedLabels: 3 },
}

const DEFAULT_JOBOPENINGS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'location': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Ort suchen' },
	'description': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Stelle suchen' },
	'status': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'JobOpeningStatusList', filterElement: 'multiTagFilterElement', filterSelectedLabel: 'Status', filterPlaceholder: 'Status wählen', maxSelectedLabels: 4 },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterPlaceholder: 'Status wählen' },
	'jobType': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'JobOpeningTypeList', filterElement: 'listFilter', filterPlaceholder: 'Typ wählen' },
	'millisecondsOpeningDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
}

const DEFAULT_PROVIDERACTIVITIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'typeActivity': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'ProvideractivitiesTypeList', filterElement: 'listFilter', filterPlaceholder: 'Typ wählen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'millisecondsEnddate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement' },
}

const DEFAULT_OVERVIEWS_PROVIDERACTIVITIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'participants': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Anzahl suchen' },
	'typeActivity': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'ProvideractivitiesTypeList', filterElement: 'listFilter', filterPlaceholder: 'Typ wählen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'millisecondsEnddate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement' },
}

const DEFAULT_REQUESTDISTRIBUTIONS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'subject': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Betreff suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Absender suchen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'regions': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Regionen wählen', filterListName: 'RegionsList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Regionen' },
	'taskareas': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Bereiche wählen', filterListName: 'TaskareasList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Aufgabenbereiche' },
}

const DEFAULT_REQUESTDISTRIBUTIONSARCHIV_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'subject': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Betreff suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Absender suchen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'regions': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Regionen wählen', filterListName: 'RegionsList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Regionen' },
	'taskareas': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Bereiche wählen', filterListName: 'TaskareasList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Aufgabenbereiche' },
	'assigned.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: '' }
}

const DEFAULT_REQUESTDISTRIBUTIONS_USERMATRIX_FILTER = {
	'displayname': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Benutzer*in suchen' },
	'regions': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Regionen wählen', filterListName: 'RegionsList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Regionen' },
	'taskareas': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Bereiche wählen', filterListName: 'TaskareasList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Aufgabenbereiche' },
}

const DEFAULT_ORGANIZATIONS_FILTER = {
	'ignoreInStatistics': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'IgnoreInStatisticesList', filterElement: 'listFilter', filterElementClassName: '', filterPlaceholder: '' },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'type': { value: (null), matchMode: FilterMatchMode.EQUALS, filterListName: 'OrganizationTypesList', filterElement: 'listFilter', filterElementClassName: '', filterPlaceholder: 'Typ wählen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: 'Status wählen' },
}

const DEFAULT_CASEREQUESTTYPES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'listentry': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Eintrag suchen" },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: 'Status wählen' },
}

const DEFAULT_REGIONS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'tagValues': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Tag suchen' },
	'postcodeValues': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'PLZ suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: 'Status wählen' },
}

const DEFAULT_TASKAREAS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'tagValues': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Tags suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: 'Status wählen' },
}

const DEFAULT_REMINDERS_FILTER = {
	'serviceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterListName: 'ServiceList', filterElement: 'listFilter', filterElementClassName: '' },
	'referenceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Im Namen suchen" },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'description': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "In der Notiz suchen" },
}

const DEFAULT_USERADMINISTRATION_FILTER = {
	'displayname': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Benutzer*in suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'E-Mail suchen' },
	'fte': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterPlaceholder: 'Status wählen' },
	'permissions': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'RolesList', filterElement: 'listFilter', filterPlaceholder: 'Rolle wählen' },
	'organization.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Träger suchen' },
}

const DEFAULT_OVERVIEWS_CASEEVENTS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'caseRequests.amountRequests': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'eaaCase.displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'eaaCase.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'millisecondsEventDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'creator.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Ersteller*in suchen', filterElement: 'responsibleFilterElement', },
	'eventType': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'EventTypeList', filterLabelName: 'display', filterFieldName: 'alias', filterElement: 'multiSelectFilterElement', filterPlaceholder: 'Arten wählen', maxSelectedLabels: 4 },
	'eventClassifiers': { value: null, matchMode: 'ArrayContainsMatchMode', filterListName: 'CaseEventClassifierList', groupedListName: 'CaseEventClassifierStructure', filterFieldName: 'caseEventClassifier', filterLabelName: 'dataview', filterElement: 'multiSelectFilterElement', filterPlaceholder: 'Themen wählen', maxSelectedLabels: 4 },
}

const DEFAULT_OVERVIEWS_CASEREQUESTS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'eaaCase.displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'creator.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', },
	'status': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'CaseRequestStatusList', filterElement: 'multiTagFilterElement', filterSelectedLabel: 'Status', filterPlaceholder: 'Status wählen', maxSelectedLabels: 2 },
	'caseRequestTypeId': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'CaseRequestTypesList', filterLabelName: 'listentry', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'ausgewählte Antragsarten', filterPlaceholder: 'Antragsarten wählen', maxSelectedLabels: 4 },
	'millisecondsRequestDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
}

const DEFAULT_OVERVIEWS_CASEPERSONS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'personId': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Personen ID suchen' },
	'GdB': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'GbB suchen' },
	'GdBMark': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Merkzeichen suchen' },
	'gender': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'GenderTypeList', filterFieldName: 'alias', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'ausgewählte Geschlechter', filterPlaceholder: 'Geschlechter wählen' },
}

const DEFAULT_OVERVIEWS_ASSOCIATEDCONTACTS_FILTER = {
	'displayName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Kontaktname suchen' },
	'regionName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'serviceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterListName: 'AssociatedContactsServiceList', filterElement: 'listFilter', filterElementClassName: '' },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', },
}

const DEFAULT_OVERVIEWS_RELATEDUSERS_FILTER = {
	'roleType': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Rolle filtern', filterListName: 'CaseRoleList', filterFieldName: 'alias', filterLabelName: 'display', filterElement: 'multiSelectFilterElement' },
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'serviceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterListName: 'AssociatedContactsServiceList', filterElement: 'listFilter', filterElementClassName: '' },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'relatedUser.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', },
	'relatedUser.organizationId': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Träger suchen' },
}

const DEFAULT_OVERVIEWS_COMPANYADDRESSES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'addressType': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'AddressTypeList', filterLabelName: 'display', filterFieldName: 'alias', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'ausgewählte Adressarten', filterPlaceholder: 'Adressart wählen', maxSelectedLabels: 2 },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: '' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: '' },
	'address': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'In Adresse suchen' },
	'postcode': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'PLZ suchen' },
	'city': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Stadt suchen' },
	'country': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Land suchen' },
}

const DEFAULT_OVERVIEWS_COMPANYCONTACTS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'contactType': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'ContactTypeList', filterLabelName: 'display', filterFieldName: 'alias', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'ausgewählte Adressarten', filterPlaceholder: 'Adressart wählen', maxSelectedLabels: 2 },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'fullname': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Ansprechpartner suchen' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: '' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: '' },
	'phone': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Telefonnummer suchen' },
	'mobile': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Mobile Nummer suchen' },
	'fax': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fax suchen' },
	'mail': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'E-Mail suchen' },
	'contactdata': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'In Kontaktdaten suchen' },
}

export const DataviewsConfig: DATAVIEW_CONFIG_ENTRY[] = [
	{ alias: ENUM_DATAVIEWS_CONFIG.EAACASES, service: 'eaacases', filtername: 'eaacases', filters: DEFAULT_EAACASES_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Faelle' },
	{ alias: ENUM_DATAVIEWS_CONFIG.COMPANIES, service: 'companies', filtername: 'companies', filters: DEFAULT_COMPANIES_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Unternehmen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.PROVIDERACTIVITIES, service: 'provideractivities', filtername: 'provideractivities', filters: DEFAULT_PROVIDERACTIVITIES_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Traegeraktivitaeten' },
	{ alias: ENUM_DATAVIEWS_CONFIG.ASSOCIATEDCONTACTS, service: 'associatedcontacts', filtername: 'associatedcontacts', filters: DEFAULT_ASSOCIATEDCONTACTS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Netzwerkkontakte' },
	{ alias: ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONS_USERMATRIX, service: 'requestdistributionsusermatrix', filtername: 'requestdistributionsusermatrix', filters: DEFAULT_REQUESTDISTRIBUTIONS_USERMATRIX_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Aufgabenverteilung-Matrix' },
	{ alias: ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONSARCHIV, service: 'requestdistributionsarchiv', filtername: 'requestdistributionsarchiv', filters: DEFAULT_REQUESTDISTRIBUTIONSARCHIV_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Aufgabenverteilung-Archiv' },
	{ alias: ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONS, service: 'requestdistributions', filtername: 'requestdistributions', filters: DEFAULT_REQUESTDISTRIBUTIONS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Aufgabenverteilung' },
	{ alias: ENUM_DATAVIEWS_CONFIG.REMINDERS, service: 'reminders', filtername: 'reminders', filters: DEFAULT_REMINDERS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Wiedervorlagen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.JOBOPENINGS, service: 'jobopenings', filtername: 'jobopenings', filters: DEFAULT_JOBOPENINGS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Stellen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.USERADMINISTRATION, service: 'users', filtername: 'useradministration', filters: DEFAULT_USERADMINISTRATION_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'NutzerInnen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.CASERQUESTTYPES, service: 'caserequesttypes', filtername: 'caserequesttypes', filters: DEFAULT_CASEREQUESTTYPES_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Antragsarten' },
	{ alias: ENUM_DATAVIEWS_CONFIG.REGIONS, service: 'regions', filtername: 'regions', filters: DEFAULT_REGIONS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Regionen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.ORGANIZATIONS, service: 'organizations', filtername: 'organizations', filters: DEFAULT_ORGANIZATIONS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Traeger' },
	{ alias: ENUM_DATAVIEWS_CONFIG.TASKAREAS, service: 'taskareas', filtername: 'taskareas', filters: DEFAULT_TASKAREAS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Bereiche' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS, headline: 'Netzwerkkontakte', service: 'overviews_associatedcontacts', filtername: 'overviews_associatedcontacts', filters: DEFAULT_OVERVIEWS_ASSOCIATEDCONTACTS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Operative-Rollen-Netzwerkkontakte' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS, headline: 'Fälle | Kontakte', service: 'overviews_caseevents', filtername: 'overviews_caseevents', filters: DEFAULT_OVERVIEWS_CASEEVENTS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Faelle-Kontakte' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS, headline: 'Fälle | Anträge', service: 'overviews_caserequests', filtername: 'overviews_caserequests', filters: DEFAULT_OVERVIEWS_CASEREQUESTS_FILTER, exportFunction: (entry: any) => { }, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Faelle-Antraege' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS, headline: 'Fälle | Fallpersonen', service: 'overviews_casepersons', filtername: 'overviews_casepersons', filters: DEFAULT_OVERVIEWS_CASEPERSONS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Faelle-Fallpersonen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS, headline: 'Operative Rollen', service: 'overviews_relatedusers', filtername: 'overviews_relatedusers', filters: DEFAULT_OVERVIEWS_RELATEDUSERS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Operative-Rollen-BearbeiterInnen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES, headline: 'Unternehmen | Adressen', service: 'overview_companyAddresses', filtername: 'overview_companyAddresses', filters: DEFAULT_OVERVIEWS_COMPANYADDRESSES_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Unternehmen-Adressen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS, headline: 'Unternehmen | Ansprechpartner*Innen', service: 'overview_companyContacts', filtername: 'overview_companyContacts', filters: DEFAULT_OVERVIEWS_COMPANYCONTACTS_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Unternehmen-AnsprechpartnerInnen' },
	{ alias: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES, headline: 'Trägeraktivitäten', service: 'overview_provideractivities', filtername: 'overview_provideractivities', filters: DEFAULT_OVERVIEWS_PROVIDERACTIVITIES_FILTER, sort: [], pagination: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }, exportFilename: 'Traegeraktivitaeten' },
]

export const getDefaultFilter = (settings: any) => {
	const filters: any = {}
	for (let entry of DataviewsConfig) {
		const value = !settings ? undefined : (settings[entry.alias] || settings[entry.filtername])
		filters[entry.alias] = value || entry.filters

		if (filters[entry.alias].instance === 'DATE' && filters[entry.alias].maxDate) {
			filters[entry.alias].maxDate = parseISO(filters[entry.alias].maxDate)
		}
		if (filters[entry.alias].instance === 'DATE' && filters[entry.alias].value) {
			filters[entry.alias].value = parseISO(filters[entry.alias].value)
		}
	}
	return filters;
}

export const getDefaultPagination = (settings: any) => {
	const paginations: any = {}
	for (let entry of DataviewsConfig) {
		const values = !settings ? undefined : (settings[entry.alias] || settings[entry.filtername])
		paginations[entry.alias] = values || entry.pagination
	}
	return paginations;
}

export const getDefaultSort = (settings: any) => {
	const sorts: any = {}
	for (let entry of DataviewsConfig) {
		const values = !settings ? undefined : (settings[entry.alias] || settings[entry.filtername])
		sorts[entry.alias] = values || entry.sort
	}
	return sorts;
}

export const getDataviewConfig = (key: ENUM_DATAVIEWS_CONFIG) => {
	const entry = DataviewsConfig.find((e: DATAVIEW_CONFIG_ENTRY) => e.alias === key)
	return entry
}