import { useFormik } from 'formik';
import { useEffect, useState } from "react";
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { InputText } from 'primereact/inputtext';
import client, { getData } from '../feathers';
import { useCompaniesInit, useCompaniesList } from "../Persistence/CompaniesContext";
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { parseISO } from 'date-fns';
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { EditorChangesChecked } from '../Components/EditorChangesChecked';
import { ENUM_ICONS } from '../Components/Icons';

export const emptyJobOpening = {
	id: null, version: 1, active: 1, description: '', explanation: '', jobType: null, openingDate: null, mobilityRequired: 0, businessTravelRequired: 0, status: null, location: '', excludedDisabilities: '', mobilityType: '', salary: '', workingHours: '', company: null, eaaCase: null
}

export const JobOpeningForm = ({ companyId, jobOpeningId, setFormHasErrors, setJobOpening, setShowDialog }) => {

	const configurationsList = useConfigurationsList();
	const useCompanyList = useCompaniesList();
	const companiesInit = useCompaniesInit();

	const [companiesList, setCompanyList] = useState([])
	const [company, setCompany] = useState();
	const [eaaCase, setEaaCase] = useState();
	const [eaaCaseList, setEaaCaseList] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		companiesInit();
	}, [])

	useEffect(() => {
		if (jobOpeningId) {
			getData('job-openings', jobOpeningId).then((result) => {
				result.openingDate = parseISO(result.openingDate)
				result.jobType = configurationsList.JobOpeningTypeList.find(e => e.alias === result.jobType);
				result.status = configurationsList.JobOpeningStatusList.find(e => e.alias === result.status);
				setCompany(result.company);
				setEaaCase(result.eaaCase);
				formik.setValues({ ...result });
				setMounted(true)
			})
		} else {
			setMounted(true)
		}

	}, [jobOpeningId, configurationsList])

	useEffect(() => {
		if (useCompanyList) {
			setCompanyList(useCompanyList.filter((entry) => entry.active))
		}
	}, [useCompanyList])

	const formik = useFormik({
		initialValues: { ...emptyJobOpening },
		validate: (data) => {
			let errors = {};

			if (!data.description) {
				errors.description = 'Beschreibung ist ein Pflichtfeld';
			}
			if (!data.jobType) {
				errors.jobType = 'Typ ist ein Pflichtfeld';
			}
			if (!data.openingDate) {
				errors.openingDate = 'Datum ist ein Pflichtfeld';
			}
			if (!data.status) {
				errors.status = 'Status ist ein Pflichtfeld';
			}
			if (!data.location) {
				errors.location = 'Ort ist ein Pflichtfeld';
			}
			if (!data.company) {
				errors.company = 'Unternehmen ist ein Pflichtfeld';
			} else {
				setCompany(data.company);
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null
			//console.log('JonOpeningForm errors', errors, data)
			setFormHasErrors(hasErrors);
			if (!hasErrors) {
				setJobOpening(data);
			}
			return errors;
		},

		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,

	});

	useEffect(() => {
		if (companyId) {
			getData('companies', companyId).then((company) => {
				formik.setFieldValue('company', company)
				setCompany(company);
			})
		}
	}, [companyId])

	useEffect(() => {
		if (company && company.id) {
			client.service('eaa-cases').find({ query: { companyId: company.id } }).then((list) => {
				const eaaCaseId = eaaCase ? eaaCase.id : null;
				setEaaCaseList(list.filter(entry => (entry.jobOpening === undefined || entry.jobOpening.eaaCaseId === eaaCaseId)));
			})
		} else {
			setEaaCaseList()
		}
	}, [company, eaaCase])



	const handleCompanySelect = (e) => {
		const value = e ? e.value : null;
		if (value === null) {
			formik.setFieldValue('eaaCase', null)
		}
		formik.setFieldValue('company', value);
		setCompany(value)
	}

	return (
		<div>{!formik ? <div></div> :
			< div className='col' >
				<form onSubmit={formik.handleSubmit}>
					<div className="formgrid grid">
						<InputChangesChecked id='description' type="text" formik={formik} label='Stellenbeschreibung' setShowDialog={setShowDialog} disabled={false} />
					</div>
					<div className="formgrid grid">
						{!mounted ? '' : <EditorChangesChecked id='explanation' value={formik.values.explanation} setValue={(value) => formik.setFieldValue('explanation', value)} label='Erläuterungen' setShowDialog={setShowDialog} disabled={false} />}
					</div>
					<div className="formgrid grid">
						{company && company.id ? <div className="field col mr-3">
							<label htmlFor='input-companyName' className='block'>Unternehmen</label>
							<div className='flex'>
								<InputText key='input-companyName' id='input-companyName' disabled={true} value={company.name} className={'w-full'} />
								<i className={formik.values.id ? 'hidden' : ENUM_ICONS.TRASH + 'w-1rem ml-1 pt-1 text-sm'} onClick={() => handleCompanySelect(null)} />
							</div>
						</div> :
							<AutoCompleteChangesChecked dropdown={true} label={'Unternehmen'} id={'company'} itemLabel={'name'} formik={formik} forceSelection={true} suggestionsList={companiesList}
								helptextcolor='#F4A261' helptext='Ein Unternehmen mit diesem Namen ist der Datenbank nicht bekannt. Beim Speichern wird das Unternehmen mit den eingegebenen Daten in der Datenbank eingetragen.'
								setShowDialog={setShowDialog} handleChange={handleCompanySelect} />}
						{!formik.values.eaaCase ?
							<DropdownChangesChecked editmode={true} label='Fall' id={'eaaCase'} filter itemLabel={'name'} formik={formik} options={eaaCaseList} setShowDialog={setShowDialog} />
							:
							<div className="field col mr-3">
								<label htmlFor='input-eaacase' className='block'>Fall</label>
								<div className='flex'>
									<InputText key='input-eaacase' id='input-eaacase' disabled={true} value={formik.values.eaaCase.name} className='flex-1' />
									<i className={ENUM_ICONS.TRASH + 'w-1rem ml-1 pt-1 text-sm'} onClick={() => formik.setFieldValue('eaaCase', null)} />
								</div>
							</div>
						}
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked editmode={true} label={'Stellenart'} id={'jobType'} itemLabel={'display'} formik={formik} options={configurationsList.JobOpeningTypeList} setShowDialog={setShowDialog} />
						<CalendarChangesChecked className='mr-3' id='openingDate' formik={formik} label='Startdatum' setShowDialog={setShowDialog} />
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked editmode={true} label={'Status'} id={'status'} itemLabel={'display'} formik={formik} options={configurationsList.JobOpeningStatusList} setShowDialog={setShowDialog} />
						<div className='field col mr-3'></div>
					</div>
					<div className="formgrid grid">
						<InputChangesChecked id='location' type='text' formik={formik} editmode={true} label='Adresse' setShowDialog={setShowDialog} />
					</div>
					<div className="formgrid grid">
						<InputChangesChecked className={'w-6'} id='workingHours' label='Arbeitsstunden' type="text" formik={formik} setShowDialog={setShowDialog} disabled={false} />
						<InputChangesChecked id='salary' label='Lohn / Gehalt' type="text" formik={formik} setShowDialog={setShowDialog} disabled={false} />
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked className={'w-6'} editmode={true} label='Mobilität erforderlich' id={'mobilityRequired'} itemLabel='label' formik={formik} options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]} setShowDialog={setShowDialog} />
						<InputChangesChecked id='mobilityType' label='Mobilitätstyp' type="text" formik={formik} setShowDialog={setShowDialog} disabled={false} />
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked className={'w-6'} editmode={true} label='Geschäftsreise erforderlich' id={'businessTravelRequired'} itemLabel='label' formik={formik} options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]} setShowDialog={setShowDialog} />
						<InputChangesChecked id='excludedDisabilities' label='Ausgeschlossene Behinderung' type="text" formik={formik} setShowDialog={setShowDialog} disabled={false} />
					</div>
				</form>
			</div >
		}</div >
	)
}
