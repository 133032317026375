import { Link } from "react-router-dom";
import { ENUM_ICONS } from "../Components/Icons";
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { ROUTES } from "../Navigation/Routes";

export const AssociatedContactCard = ({ associatedContact, onDeleteHandler, readonly }) => {
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const onShowHandler = (associatedContact) => {
		const route = ROUTES['ASSOCIATEDCONTACTS']
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + associatedContact.id }] })

	}


	const renderContent = () => {
		const { phone, mobile, email } = associatedContact
		return <div className="flex align-content-end flex-column h-5rem px-2 py-1">
			<div className={phone ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col '>{phone}</div></div>
			<div className={mobile ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col '>{mobile}</div></div>
			<div className={email ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col '><a href={`mailto:${email}`}>{email}</a></div></div>
		</div>
	}
	const renderTitle = (associatedContact) => {
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{associatedContact.name}
				</div>
				<div>
					{associatedContact.organization}
				</div>
			</div>
			<div className={readonly ? 'hidden' : "flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.EYE + 'title-eye'} onClick={() => { onShowHandler(associatedContact) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(associatedContact) }} />
			</div>
		</div>
	}

	return (<>
		{!associatedContact || associatedContact.active === 0 ? '' :
			<div id='associatedcontact-card' className="eaa-card associatedcontact-card shadow-2">
				<div>{renderTitle(associatedContact)}</div>
				{renderContent(associatedContact)}
			</div>
		}
	</>)
}