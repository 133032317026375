import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { TemplateDataView } from '../Components/TemplateDataView';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useProviderActivitiesInit, useProviderActivitiesList } from '../Persistence/ProviderActivitiesContext';
import { defaultProviderActivity, ProviderActivitiesForm, validateProviderActivity } from './ProviderActivitiesForm';
import { ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';


const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.PROVIDERACTIVITIES);
export const ProviderActivitiesDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const providerActivitiesList = useProviderActivitiesList();
	const initProviderActivities = useProviderActivitiesInit();
	const [displayProviderActivityDialog, setDisplayProviderActivityDialog] = useState(false);
	const [dataviewList, setDataviewList] = useState([])


	const navigate = useNavigate();

	useEffect(() => {
		initProviderActivities();
	}, [])

	useEffect(() => {
		setDataviewList(providerActivitiesList);
	}, [providerActivitiesList])

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.PRACTIVITY !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [configurationsList, navigate])

	const patchProviderActivity = async () => {
		await patchData('provideractivities', formik.values, currentUser).then((provideractivity) => {
			addBreadcrumb(`${provideractivity.name}`, `${ENUM_ROUTES.PROVIDERACTIVITIES}/` + provideractivity.id);
		})
	}
	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", style: { width: '100px' }, body: null },
		{ field: "name", sortable: true, filter: "name", filterPlaceholder: "Name suchen", header: "Name der Trägeraktivität", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "typeActivity", sortable: true, filter: "typeActivity", filterPlaceholder: "Name suchen", header: "Typ", style: { flexGrow: 1, flexBasis: '200px' }, body: 'renderList' },
		{ field: "responsible", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { flexGrow: 1, flexBasis: '200px' }, body: renderResponsible },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Datum der Trägeraktivität", style: { width: '180px' }, body: 'renderDate', },
		{ field: "millisecondsEnddate", sortable: true, filter: "millisecondsEnddate", header: "Wiederkehrend bis", style: { width: '200px' }, body: 'renderDate' }
	]

	const formik = useFormik({
		initialValues: { ...defaultProviderActivity, userId: currentUser.id },
		validate: validateProviderActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (
		<div className='mx-auto eaa-dataView select-none' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				form={<ProviderActivitiesForm formik={formik} key='providerActivity-form' />}
				formik={formik}
				patchForm={patchProviderActivity}
				header={'Neue Trägeraktivität anlegen'}
				message={'Hier können sie eine neue Trägeraktivität anlegen'}
				setDisplayDialog={setDisplayProviderActivityDialog}
				displayDialog={displayProviderActivityDialog}
			/>

			<TemplateDataView
				columns={columns}
				showOpenLinkColumn={true}
				dataTableList={dataviewList}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
				buttons={[<Button
					key='button-new-provideractivity'
					disabled={currentUser.permissions === 'READER'}
					onClick={() => { formik.setValues({ ...defaultProviderActivity, userId: currentUser.id }); setDisplayProviderActivityDialog(true) }}
					className='flex  text-white' label='Trägeraktivität anlegen' />]}
				ROUTE={ENUM_ROUTES.PROVIDERACTIVITIES} />

		</div>
	)

}