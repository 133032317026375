import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { useCurrentUser, useCurrentUserUpdate } from "./CurrentUserContext";

const listenernames = ['created', 'patched'];
const providername = 'OrganizationsProvider';
const servicename = 'organizations';
const OrganizationsContext = React.createContext();
const OrganizationsSelectionContext = React.createContext();
const CLASSNAME = 'OrganizationsProvider'

export function useOrganizationsSelectionList() {
	return useContext(OrganizationsSelectionContext);
}

export function useOrganizationsList() {
	return useContext(OrganizationsContext);
}

export function OrganizationsProvider({ children }) {
	const currentUser = useCurrentUser();
	const currentUserUpdate = useCurrentUserUpdate();
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(CLASSNAME, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			if (listItemChanged && listItemChanged.id === currentUser.organizationId) {
				currentUserUpdate({ ...currentUser, organization: listItemChanged })
				logContext(CLASSNAME, 'info', ' update organization of currentUser ');
			}
			setListItemChanged(null);
		}
	}, [listItemChanged, list, currentUser, currentUserUpdate])

	useEffect(() => {
		logContext(CLASSNAME, 'info', '########### ' + providername + ' update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
		const sortedList = list.sort((a, b) => {
			let x = a.name.toLowerCase();
			let y = b.name.toLowerCase();
			if (x < y) { return -1; }
			if (x > y) { return 1; }
			return 0;
		})
		setListSelection(sortedList.filter((entry) => entry.active))
	}, [list])

	useEffect(() => {
		if (mounted) {
			getData(servicename).then((_list) => {
				setList(_list);
				setListSelection(_list.filter((entry) => entry.active))
				logContext(CLASSNAME, 'info', `########### ${providername} useEffect found:${_list.length}`, _list)
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.info(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<OrganizationsContext.Provider value={list}>
			<OrganizationsSelectionContext.Provider value={listSelection}>
				{children}
			</OrganizationsSelectionContext.Provider>
		</OrganizationsContext.Provider>
	)
}