import { format, parseISO } from "date-fns";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { Divider } from 'primereact/divider';
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { useDataViewFilterUpdate, useDataViewPagination, useDataViewPaginationUpdate } from "../Persistence/DataViewFilterContext";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsMinus, useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { logDefault } from "../Utils/logger";
import { CompanyForm, emptyCompany, validateCompany } from "./CompanyForm";
import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import exportToPDF from "../Utils/exportToPDF";
import { ToastError } from "../Utils/ToastError";
import { CommentsModule } from "../Components/CommentsModule";
import { CompanyJobOpenings } from "./CompaniesJobOpenings";
import { CompanySheet } from "./CompanySheet";
import { RELATEDUSERSERVICECONFIG, RelatedUsersSheet, sortRelatedUser } from "../RelatedUsers/RelatedUsersSheet";
import { Link, useParams } from "react-router-dom";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from "../AssociatedContacts/AssociatedContactSheet";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { deleteCompany, getCompany, patchCompany, toggleCompanyStatus } from "./CompanyManager";
import { copyObject } from "../snippets";
import { EAACaseCreateWizard } from "../EAACase/EAACaseCreateWizard";
import { DEFAULT_EAACASES_FILTER, ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from "../Enums/ENUM_DATAVIEWS_CONFIG";

export const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];
const CLASSNAME = 'CompaniesData';
const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.EAACASES);
const SERVICENAME = ENUM_SERVICES.COMPANIES;
export const CompaniesData = () => {
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const isReader = useCurrentUserIsReader();
	const { companyId } = useParams();
	const [company, setCompany] = useState(null);
	const [items, setItems] = useState([]);
	const [jobOpening, setJobOpening] = useState();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [deleteDisplayConfirmDialog, setDeleteDisplayConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const dataViewFilterUpdate = useDataViewFilterUpdate();
	const dataViewPagination = useDataViewPagination();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [toastParams, setToastParams] = useState(null);
	const [readonly, setReadonly] = useState(true);

	useEffect(() => {
		onUpdateCompany()
	}, [companyId])

	useEffect(() => {
		const cases = company && company.eaaCases && company.eaaCases.length > 0;
		const jobOpenings = company && company.jobOpenings && company.jobOpenings.length > 0;
		const items = isReader ? [] : [
			{ label: 'Bearbeiten', disabled: company && !company.active, icon: ENUM_ICONS.PENCIL, command: handleEditCompany },
		]
		items.push({ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportCompany },
		)
		if (!isReader && company) {
			items.push({ separator: true })
			if (company.active) {
				items.push({ label: 'Inaktiv setzen', icon: ENUM_ICONS.DEACTIVATE, command: () => setDisplayConfirmDialog(true) })
			} else {
				items.push({ label: 'Aktiv setzen', icon: ENUM_ICONS.REACTIVATE, command: () => setDisplayConfirmDialog(true) })
			}
		}

		if (isAdmin && company) {
			items.push({ separator: true })
			items.push({ label: 'Unternehmen unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.COMPANY_DELETE, disabled: (cases || jobOpenings), command: () => setDeleteDisplayConfirmDialog(true) })
		}
		setItems(items);
	}, [company])

	const onUpdateCompany = async () => {
		if (companyId) {
			logDefault(CLASSNAME, 'info', 'onUpdateCompany companyId:' + companyId)
			await getCompany(companyId, isReader, setCompany, setReadonly, setToastParams)
		}
	}

	const onClickEAACases = () => {
		const filters = {
			...DEFAULT_EAACASES_FILTER,
			'company.name': { ...DEFAULT_EAACASES_FILTER['company.name'], value: company.name },
			displayId: { ...DEFAULT_EAACASES_FILTER.displayId, value: null },
			name: { ...DEFAULT_EAACASES_FILTER.name, value: null },
			millisecondsCreatedAt: { ...DEFAULT_EAACASES_FILTER.millisecondsCreatedAt, value: null },
			'responsible.id': { ...DEFAULT_EAACASES_FILTER['responsible.id'], value: null },
			status: { ...DEFAULT_EAACASES_FILTER.status, value: null },
		}
		dataViewFilterUpdate(DATAVIEW_CONFIG, filters);
		const paginationRows = DATAVIEW_CONFIG.pagination.paginationRows
		setDataViewPagination(DATAVIEW_CONFIG, { paginationFirst: 0, paginationRows, currentPage: 0 })
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fälle', route: ENUM_ROUTES.EAACASES }] })
	}

	const handleEditCompany = () => {
		formikCompany.setValues(copyObject(company))
		setDisplayDialog(true);
	}

	const handleExportCompany = async () => {
		await exportToPDF('COMPANY', copyObject(company), null, configurationsList.eaaModules);
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyCompany)),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title={'Unternehmen ' + (company.active ? 'deaktivieren' : 'reaktivieren')} message={'Wollen Sie das Unternehmen ' + (company.active ? 'deaktivieren?' : 'reaktivieren?')} labelOk='Ja'
				handleOnClick={async () => await toggleCompanyStatus(company, currentUser, isReader, setCompany, setReadonly, setToastParams)}
				displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<ConfirmDialog title={'Unternehmen unwiderruflich löschen'} message={'Wollen Sie das Unternehmen wirklich unwiderruflich löschen?\nAlle Informationen zu Adressen und Ansprechparter*innen diese Unternehmens werden auch gelöscht!'} labelOk='Ja'
				handleOnClick={async () => await deleteCompany(company, minusBreadcrumbs, setToastParams)}
				displayConfirmDialog={deleteDisplayConfirmDialog} setDisplayConfirmDialog={setDeleteDisplayConfirmDialog} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={company.name} serviceId={company.id} serviceName='companies' userId={currentUser.id} />
			<EditDialog
				displayDialog={displayDialog}
				formik={formikCompany}
				labelButtonOk='Ändern'
				header='Unternehmensdetails ändern'
				message=''
				patchForm={async () => { await patchCompany(formikCompany.values, currentUser, isReader, setCompany, setReadonly, setToastParams) }}
				setDisplayDialog={setDisplayDialog}
				form={<CompanyForm
					formik={formikCompany}
					readOnly={false}
					handleSelectExistingCompany={onUpdateCompany}
				/>}
			/>
		</>
	}

	const renderContent = () => {
		const status = listStatus.find(entry => entry.alias === company.active);
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				<div className='mx-auto eaa-maincontent flex flex-column'>
					<div className='flex flex-column'>
						<div className='flex justify-content-end mb-4 gap-2'>
							<Button onClick={handleEditCompany} icon={ENUM_ICONS.PENCIL} disabled={isReader} className='flex text-white color-background-lessblack' label='Bearbeiten' />
							<EAACaseCreateWizard company={company} icon={ENUM_ICONS.FOLDER_PLUS} ENUM_AWESOMEICON={ENUM_AWESOMEICON.FOLDER_PLUS} />
							<Button onClick={onClickEAACases} icon={ENUM_ICONS.FOLDER_OPEN_O} className='flex text-white color-background-lessblack' label='Fälle des Unternehmens anzeigen' />
							<Button onClick={handleUseForReminder} icon={ENUM_ICONS.CLOCK} disabled={isReader} className='flex text-white color-background-lessblack' label='Wiedervorlage' />
						</div>
						<div className='flex grid'>
							<div className='col flex  flex-column  text-4xl '>
								<div className='flex text-center justify-content-center font-semibold'>
									{company.name}
								</div>
								<div className='flex text-center justify-content-center text-lg m-2 gap-2'>
									<div>{'' + company.displayId + ' erstellt am ' + (company.createdAt ? format(parseISO(company.createdAt), 'yyyy-MM-dd') : '') + (company.creator ? (' von ' + company.creator.displayname) : '')}</div>
									<Tag className={'mr-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />
								</div>
							</div>
						</div>
						<div className='flex card-container blue-container overflow-hidden my-3 grid'>
							<FieldInfo label='Telefon' content={company.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-2x'} />} />
							<FieldInfo label='Fax  ' content={company.fax} icon={<i className={ENUM_ICONS.FAX + 'fa-2x'} />} />
							<FieldInfo label='Mobil' content={company.mobile} icon={<i className={ENUM_ICONS.MOBILE + 'fa-3x'} />} />
							<FieldInfo label='E-Mail' content={<a href={`mailto:${company.mail}`}>{company.mail}</a>} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-2x'} />} />
						</div>
					</div>
					<Divider />
					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Unternehmensbeschreibung
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: company.description }} />
					</div>
					<div className="flex flex-column mt-4 mr-3">
						<CommentsModule onUpdateComment={onUpdateCompany} key='companyCommentsModule' servicename='company-comments' readonly={readonly} parentId={company.id} idFieldname='companyId' comments={company.comments} />
					</div>
				</div>
			</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg", component: <CompanySheet readonly={readonly} company={company} onUpdateCompany={onUpdateCompany} hideSpecialFields={true} /> },
			{ index: 1, visible: configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING === '1', icon: ENUM_ICONS.JOBPROFILE + "fa-lg", component: <CompanyJobOpenings readonly={readonly} company={company} jobOpenings={jobOpening ? jobOpening : []} numCarousel={1} onUpdateCompany={onUpdateCompany} hideDivider={true} /> },
			{
				index: 2, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet relatedUsers={sortRelatedUser(company.companyUsers)} onRelatedUserPatched={onUpdateCompany} readonly={readonly} key='relatedUserSheet' parentId={company.id} relatedUserServcieConfig={RELATEDUSERSERVICECONFIG.COMPANY} hideDivider={true} />
					<AssociatedContactSheet associatedContacts={company.associatedContacts} onAssociatedContactPatched={onUpdateCompany} readonly={readonly} associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.COMPANY} parent={company} />
				</div>
			},
		]
		return sideSheets;
	}

	return (!company ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!company ? '' : renderContent()}{!company ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={items} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={company.id}
		/>)

}
