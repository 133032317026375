import { Button } from 'primereact/button';
import { useState, useEffect } from 'react';
import { TemplateDataView } from '../Components/TemplateDataView';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useCompaniesInit, useCompaniesList } from '../Persistence/CompaniesContext';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { CompanyForm, emptyCompany, validateCompany } from './CompanyForm';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useFormik } from 'formik';
import { openCompany, patchCompany } from './CompanyManager';
import { copyObject } from '../snippets';
import { ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';

const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.COMPANIES);
export const CompaniesDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const [dataviewList, setDataviewList] = useState([])
	const companiesList = useCompaniesList();
	const companiesInit = useCompaniesInit();
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);

	useEffect(() => {
		companiesInit();
	}, [])

	useEffect(() => {
		setDataviewList(companiesList);
	}, [companiesList])

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", style: { width: '100px' }, body: null },
		{ field: "name", sortable: true, filter: "name", filterPlaceholder: "Unternehmen suchen", header: "Name des Unternehmens", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "responsible", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { flexGrow: 1, flexBasis: '200px' }, body: renderResponsible },
		{ field: "employees", header: "Mitarbeiter", style: { width: '110px' }, align: 'right' },
		{ field: "employeesSbM", header: "SbM", style: { width: '60px' }, align: 'right' },
		{ field: "quote", sortable: true, header: "Quote", style: { width: '70px' }, align: 'right', body: (rowData) => { return rowData.quote.toLocaleString("de-DE", { style: "percent" }) }, },
		{ field: "millisecondsCreatedAt", sortable: true, filter: "millisecondsCreatedAt", header: "Anlagedatum", style: { width: '180px' }, body: 'renderDate' },
		{ field: "region.name", sortable: true, filter: "region.name", filterPlaceholder: "Region suchen", header: "EAA-Region", style: { width: '200px' }, body: null },
		{ field: "active", sortable: true, filter: "active", header: "Status", style: { width: '150px' }, body: 'renderTag' }
	]

	const exportFunction = (entry) => {
		const value = {
			'ID': entry.displayId,
			'Name': entry.name,
			'E-Mail': entry.mail,
			'Telefon': entry.phone,
			'Fax': entry.fax,
			'Beschäftigungsflichtig:': entry.employmentObligation
		}
		return value
	}

	return (
		<div className='mx-auto eaa-dataView select-none ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				displayDialog={displayCompanyDialog}
				formik={formikCompany}
				labelButtonOk='Anlegen'
				header='Unternehmen anlegen'
				message='Lege ein neues Unternehmen an'
				patchForm={async () => {
					await patchCompany(formikCompany.values, currentUser).then((company) => {
						addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + company.id);
					})
				}}
				setDisplayDialog={setDisplayCompanyDialog}
				form={<CompanyForm
					formik={formikCompany}
					readOnly={false}
					handleSelectExistingCompany={(id) => { formikCompany.setTouched({}); openCompany(id, addBreadcrumb) }}
				/>}
			/>
			<TemplateDataView
				columns={columns}
				showOpenLinkColumn={true}
				dataTableList={dataviewList}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
				exportFunction={exportFunction}
				buttons={[<Button key={'button-new-company'} disabled={isReader} onClick={() => { formikCompany.setValues(copyObject(emptyCompany)); setDisplayCompanyDialog(true) }} className='flex  text-white' label='Unternehmen anlegen' />]}
				ROUTE={ENUM_ROUTES.COMPANIES} />

		</div>
	)

}