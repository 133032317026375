import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_ICONS } from '../Components/Icons';
import { BasicSheetTitleLayout } from '../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../feathers';
import { useBreadcrumbsMinus } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { ToastError } from '../Utils/ToastError';
import { hasParticipants, ProviderActivitiesForm, defaultProviderActivity, validateProviderActivity } from './ProviderActivitiesForm';
import { BasicSheetLayout } from '../Components/Layouts/BasicSheetLayout';
import { RELATEDUSERSERVICECONFIG, RelatedUsersSheet, sortRelatedUser } from '../RelatedUsers/RelatedUsersSheet';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { CommentsModule } from '../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { getProviderActivity, getTypeAvtivityObject } from './ProviderActivitiesManager';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from '../AssociatedContacts/AssociatedContactSheet';


const SERVICENAME = ENUM_SERVICES.PROVIDERACTIVITIES;
const CLASSNAME = 'ProviderActivityData'
export const ProviderActivityData = () => {
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const configurationsList = useConfigurationsList();
	const { provideractivityId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [providerActivity, setProviderActivity] = useState();
	const [displayProviderActivityDialog, setDisplayProviderActivityDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	const providerActivityUpdate = async () => {
		if (provideractivityId) {
			return await getProviderActivity(configurationsList, provideractivityId).then((providerActivity) => {
				setReadonly(isReader)
				setProviderActivity(providerActivity);
				logDefault(CLASSNAME, 'info', 'providerActivity ', providerActivity);
				return providerActivity !== null
			}).catch((error) => {
				setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
			});
		}
	}

	useEffect(() => {
		setTimeout(async () => {
			if (!await providerActivityUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		}, 100);
	}, [errorUpdate, navigate])

	const patchProviderActivity = async () => {
		await patchData(SERVICENAME, formik.values).then(async (patchedProviderActivity) => {
			await providerActivityUpdate();
		})
	}

	const onEditActivity = () => {
		formik.setValues({ ...providerActivity, endDate: providerActivity.endDate ? parseISO(providerActivity.endDate) : null, recurring: providerActivity.recurring === 1 })
		setDisplayProviderActivityDialog(true)
	}

	const deleteActivity = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData(SERVICENAME, providerActivity.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Trägeraktivität konnte nicht gelöscht werden', message: error.message })
		})
	}
	const renderItems = () => {
		const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditActivity, disabled: readonly }]
		if (currentUser.permissions === 'ADMIN' && providerActivity) {
			items.push({ label: 'Trägeraktivität unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}
	const formik = useFormik({
		initialValues: { ...defaultProviderActivity, userId: currentUser.id },
		validate: validateProviderActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const listButtons = [
		{ key: 'edit', label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditActivity, disabled: readonly },
		{ key: 'reminder', label: 'Wiedervorlage', icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderContent = () => {
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						listButtons={listButtons}
						title={providerActivity.name}
						subtitle={providerActivity.displayId + ' erstellt am ' + (providerActivity.createdAt ? format(parseISO(providerActivity.createdAt), 'yyyy-MM-dd') : '') + (providerActivity.creator ? (' von ' + providerActivity.creator.displayname) : '')}
					/>

					<div className='flex card-container blue-container overflow-hidden grid'>
						<FieldInfo label='Typ' content={getTypeAvtivityObject(configurationsList, providerActivity.typeActivity).display} className='col-5' />
						<FieldInfo label='Ersteller*in der Trägeraktivität' content={!providerActivity.creator ? '' : providerActivity.creator.displayname} className='hidden col-5' />
						{hasParticipants(providerActivity.typeActivity)
							? <FieldInfo label='Teilnehmerzahlen' content={providerActivity.participants} className='col-2' />
							: providerActivity.typeActivity === 'COOPERATION_MEETINGS' ? '' : <FieldInfo label='Wiederkehrend bis' content={providerActivity.endDate ? format(parseISO(providerActivity.endDate), 'yyyy-MM-dd') : '---'} className='col-2' />}
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Beschreibung der Trägeraktivität
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: providerActivity.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule onUpdateComment={providerActivityUpdate} key='provideractivityCommentsModule' servicename={ENUM_SERVICES.PROVIDERACTIVITYCOMMENTS} readonly={readonly} parentId={providerActivity.id} idFieldname='provideractivityId' comments={providerActivity.comments} />
					</div>
				</div>
			</div></>
		)
	}
	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title='Trägeraktivität löschen' message={`<span>Wollen Sie die Trägeraktivität<br/><b>${providerActivity.name}</b><br/>unwiederruflich löschen?<span>`} labelOk='Ja'
				handleOnClick={deleteActivity} displayConfirmDialog={displayDeleteConfirmDialog} setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Trägeraktivität' name={providerActivity.name} serviceId={providerActivity.id} serviceName='provideractivities' userId={currentUser.id} />
			<EditDialog
				form={<ProviderActivitiesForm formik={formik} key='providerActivity-form' />}
				formik={formik}
				patchForm={patchProviderActivity}
				header={'Trägeraktivität bearbeiten'}
				message={'Hier können sie die Trägeraktivität bearbeiten'}
				setDisplayDialog={setDisplayProviderActivityDialog}
				displayDialog={displayProviderActivityDialog}
			/>
		</>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet relatedUsers={sortRelatedUser(providerActivity.provideractivityUsers)} onRelatedUserPatched={providerActivityUpdate} readonly={readonly} key='relatedUserSheet' parentId={providerActivity.id} relatedUserServcieConfig={RELATEDUSERSERVICECONFIG.PROVIDERACTIVITY} hideDivider={true} />
					<AssociatedContactSheet associatedContacts={providerActivity.associatedContacts} onAssociatedContactPatched={providerActivityUpdate} readonly={readonly} associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.PROVIDERACTIVITY} parent={providerActivity} />
				</div>
			},
		]
		return sideSheets;
	}

	return (!providerActivity ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!providerActivity ? '' : renderContent()}{!providerActivity ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={providerActivity.id}
		/>
	)
}