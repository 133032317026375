import { useFormik } from "formik";
import { useEffect, useState } from 'react'
import { CompanyForm, emptyCompany, validateCompany } from "../Companies/CompanyForm";
import { EAACaseForm, emptyEAACase, validateEAACase } from "./EAACaseForm";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { useCurrentUser, useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { CompanyChooseDialog } from "../Companies/CompanyChooseDialog";
import { getCompany, patchCompany } from "../Companies/CompanyManager";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { patchEAACase } from "./EAACaseManager";
import { addAssociatedContactId } from "../AssociatedContacts/AssociatedContactsManager";
import { ASSOCIATEDCONTACTCONFIG } from "../AssociatedContacts/AssociatedContactSheet";
import { logDefault } from "../Utils/logger";
import { copyObject } from "../snippets";
import { patchCaseEvent } from "../CaseEvents/CaseEventsManager";
import { startOfDay } from "date-fns";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, Icon } from "../Components/Icons";


const CLASSNMAE = 'EAACaseCreateWizard'
export const EAACaseCreateWizard = ({ company, ENUM_AWESOMEICON: icon, disabled }) => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader()
	const addBreadcrumb = useBreadcrumbsAdd();
	const [companyId, setCompanyId] = useState();
	const [companyEAACase, setCompanyEAACase] = useState();
	const [step, setStep] = useState(0)

	useEffect(() => {
		if (company) {
			setCompanyEAACase(company)
		}
	}, [company])

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	/** formik EaaCase */
	const formikEAACase = useFormik({
		initialValues: copyObject(emptyEAACase),
		validate: validateEAACase,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const startWizard = () => {
		formikCompany.setValues(copyObject(emptyCompany));
		formikEAACase.setValues(copyObject(emptyEAACase))
		setStep(company ? 3 : 2)
	}

	const handleChange = (company) => {
		setCompanyId(company ? company.id : null)
	}

	const handleSelectExistingCompany = async (companyId) => {
		formikCompany.setTouched({})
		let companyTemp = null
		if (companyId) {
			companyTemp = await getCompany(companyId).then(company => {
				formikCompany.setValues(company)
				return company
			})
		} else {
			formikCompany.setValues(copyObject(emptyCompany))
		}
		setCompanyEAACase(companyTemp);
	}

	return <>
		<Dialog header='Neuen Fall anlegen - Unternehmen auswählen' visible={step === 1} onHide={() => setStep(0)} id="dialog-chooseCompany" className='eaa-inputdialog' >
			<CompanyChooseDialog handleChange={handleChange} />
			<div className="flex flex-row my-2 justify-content-between">
				<div className='flex '>
					<Button onClick={() => setStep(2)} className="flex-order-0 " label={'Neues Unternehmen anlegen'} />
				</div>
				<div className='flex gap-3 mr-3'>
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setStep(0)} />
					<Button disabled={!companyId} onClick={() => setStep(3)} className="flex-order-0 " label={'Auswählen'} />
				</div>
			</div>
		</Dialog>

		<EditDialog
			header={<div className='mt-2'>
				<div>{'Fall anlegen \u2192 Unternehmen anlegen/auswählen'}</div>
			</div>}
			form={
				<CompanyForm
					readOnly={companyEAACase ? true : false}
					formik={formikCompany}
					showTrash={true}
					handleSelectExistingCompany={handleSelectExistingCompany}
				/>
			}
			formik={formikCompany}
			displayDialog={step === 2}
			setDisplayDialog={setStep}
			labelButtonOk={companyEAACase ? 'Weiter' : 'Anlegen'}
			patchForm={async () => {
				if (companyEAACase) {
					setStep(3);
				} else {
					await patchCompany(formikCompany.values, currentUser).then((company) => {
						setCompanyEAACase(company);
						setCompanyId(company.id);
						formikEAACase.setValues(copyObject(emptyEAACase))
						setStep(3);
					})
				}
			}}
		/>

		<EditDialog
			header={<div className='mt-2'>
				<div>{'Fall anlegen \u2192 Falldetails für ' + (!companyEAACase ? '' : companyEAACase.name) + ' erfassen'}</div>
			</div>}
			form={
				<EAACaseForm
					formik={formikEAACase}
					editmode={true}
					key={'EaaCaseForm'} />
			}
			formik={formikEAACase}
			displayDialog={step === 3}
			setDisplayDialog={setStep}
			labelButtonOk='Anlegen'
			patchForm={async () => {
				const eaaCase = { ...formikEAACase.values, companyId: companyEAACase.id }
				await patchEAACase(eaaCase).then(async (eaaCase) => {
					if (formikEAACase.values.initiatedBy === 'THIRDPARTY' && formikEAACase.values.associatedContactId && formikEAACase.values.associatedContactId !== '') {
						/** 
						 * Wenn initiiert durch Dritte und eine Netzwerkorganisation ausgewählt wurde
						 * dann diesen Netzwerkkontakt zum Fall hinzufügen
						 */
						logDefault(CLASSNMAE, 'info', 'addAssociatedContactId: ' + formikEAACase.values.associatedContactId)
						await addAssociatedContactId(ASSOCIATEDCONTACTCONFIG.CASE, formikEAACase.values.associatedContactId, eaaCase).then(async (associatedContact) => {
							/** erstelle einen Transfer Event zu diesem Netzwerkkontakt */
							const caseEvent = { eventDate: startOfDay(new Date()), eventTime: startOfDay(new Date()), eventType: 'CASERECEIVE', description: '', transferOrganization: associatedContact.organization, transferUser: associatedContact.name }
							await patchCaseEvent(caseEvent, eaaCase)
						})
					}
					/** öffne neu erstellten Fall */
					addBreadcrumb(`${eaaCase.name}`, `${ENUM_ROUTES.EAACASES}/` + eaaCase.id);
				})
			}}
		/>
		<Button disabled={isReader || (company && !company.active)} className={'flex text-white ' + (company ? 'color-background-lessblack' : '')} onClick={startWizard} >
			{!icon ? '' : <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.FOLDER_PLUS} size={ENUM_AWESOMEICON_SIZE.X1} className='mr-2' />}
			<div>Fall anlegen</div>
		</Button>
	</>

}