import { useState, useEffect } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { readOverviewData } from './OverviewManager';
import { LoadingMessageDataView } from '../Components/LoadingMessageDataView';
import { ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';

const CLASSNAME = 'OverviewCasePersonsDataview'
const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS);
export const OverviewCasePersonsDataview = () => {
	const configurationsList = useConfigurationsList()
	const [eaaCasesPersonsList, setEaaCasesPersonsList] = useState();
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => await readOverviewData(DATAVIEW_CONFIG.alias, 50, setEaaCasesPersonsList, setCountRecords), 1)
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderGender = (rowData) => {
		const gender = !configurationsList ? undefined : configurationsList.GenderTypeList.find(e => e.alias === rowData.gender)
		return gender ? gender.display : rowData.gender
	}

	const columnsCasePersons = [
		{ field: "displayId", sortable: true, filter: true, header: "Fall ID", style: { width: '150px' } },
		{ field: "name", sortable: true, filter: true, header: "Name", style: { flexGrow: 1, flexBasis: '150px' } },
		{ field: "millisecondsDate", sortable: true, filter: true, header: "Falldatum", style: { width: '180px' }, body: 'renderDate' },
		{ field: "company.name", sortable: true, filter: true, header: "Unternehmen", style: { flexGrow: 1, flexBasis: '150px' }, className: '', body: (rowData) => { return (rowData.company) ? rowData.company.name : '' } },
		{ field: "personId", sortable: true, filter: true, header: "Personen ID", style: { width: '200px' } },
		{ field: "GdB", sortable: true, filter: true, header: "GdB", style: { width: '100px' } },
		{ field: "GdBMark", sortable: true, filter: true, header: "Merkzeichen", style: { width: '200px' } },
		{ field: "gender", sortable: true, filter: true, header: "Geschlecht", style: { width: '150px' }, body: renderGender },
	]

	return <div className='w-full overflow-auto'>
		<div className='' >
			<TemplateDataView
				showOpenLinkColumn={true}
				key='datatable-overviews-caserequests'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				dataTableList={eaaCasesPersonsList}
				ROUTE={ENUM_ROUTES.EAACASES}
				columns={columnsCasePersons}
				loadingMessage={<LoadingMessageDataView countRecords={countRecords} />}
				dataTableWidth='100%'
				DATAVIEW_CONFIG={DATAVIEW_CONFIG} />
		</div>
	</div>
}