import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_AWESOMEICON, ENUM_ICONS } from '../Components/Icons';
import { BasicSheetTitleLayout } from '../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../feathers';
import { useBreadcrumbsMinus } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { ToastError } from '../Utils/ToastError';
import { BasicSheetLayout } from '../Components/Layouts/BasicSheetLayout';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { CommentsModule } from '../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { AssociatedContactForm, getEmptyAssociatedContact, validateAssociatedContact } from './AssociatedContactForm';
import { getAssociatedContact } from './AssociatedContactsManager';
import { Card } from "primereact/card";


const SERVICENAME = ENUM_SERVICES.ASSOCIATEDCONTACTS;
const CLASSNAME = 'AssociatedContactData'
export const AssociatedContactData = () => {
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const configurationsList = useConfigurationsList();
	const { associatedContactId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [associatedContact, setAssociatedContact] = useState();
	const [displayAssociatedContactDialog, setDisplayAssociatedContactDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [relations, setRelations] = useState(1);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1') {
			errorUpdate('Modul Netzwerkkontakte', 'Das Modul Netzwerkkontakte ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	}, [])

	const associatedContactUpdate = async () => {
		if (associatedContactId) {
			return await getAssociatedContact(associatedContactId).then((associatedContact) => {
				setReadonly(isReader)
				const countRelations = associatedContact.companies.length + associatedContact.eaaCases.length + associatedContact.provideractivities.length
				setRelations(countRelations)
				setAssociatedContact(associatedContact);
				logDefault(CLASSNAME, 'info', 'associatedContact ', associatedContact);
				return associatedContact !== null
			}).catch((error) => {
				setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
			});
		}
	}

	useEffect(() => {
		setTimeout(async () => {
			if (!await associatedContactUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		}, 100);
	}, [])

	const patchAssociatedContact = async () => {
		await patchData(SERVICENAME, formik.values).then(async () => {
			await associatedContactUpdate();
		})
	}

	const onEditAssociatedContact = () => {
		formik.setValues({ ...associatedContact })
		setDisplayAssociatedContactDialog(true)
	}

	const deleteAssociatedContact = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData(SERVICENAME, associatedContact.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Netzwerkkontakt konnte nicht gelöscht werden', message: error.message })
		})
	}
	const renderItems = () => {
		const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditAssociatedContact, disabled: readonly }]
		if (currentUser.permissions === 'ADMIN' && associatedContact) {
			items.push({ label: 'Netzwerkkontakt unwiderruflich löschen', disabled: relations !== 0, className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}
	const formik = useFormik({
		initialValues: getEmptyAssociatedContact(),
		validate: validateAssociatedContact,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const listButtons = [
		{ key: 'edit', label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditAssociatedContact, disabled: readonly },
		{ key: 'reminder', label: 'Wiedervorlage', icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderContent = () => {
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						listButtons={listButtons}
						title={associatedContact.name}
						subtitle={associatedContact.displayId + ' erstellt am ' + (associatedContact.createdAt ? format(parseISO(associatedContact.createdAt), 'yyyy-MM-dd') : '') + (associatedContact.creator ? (' von ' + associatedContact.creator.displayname) : '')}
					/>

					<div className='flex card-container blue-container overflow-hidden my-3 grid'>
						<FieldInfo label='Telefon' content={associatedContact.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-2x'} />} />
						<FieldInfo label='Mobil' content={associatedContact.mobile} icon={<i className={ENUM_ICONS.MOBILE + 'fa-3x'} />} />
						<FieldInfo label='E-Mail' content={<a href={`mailto:${associatedContact.email}`}>{associatedContact.email}</a>} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-2x'} />} />
					</div>
					<div className='flex card-container blue-container overflow-hidden my-3 grid'>
						<FieldInfo label='Organisation' content={associatedContact.organization} />
						<FieldInfo label='Region' content={associatedContact.region ? associatedContact.region.name : ''} />
					</div>
					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Kurznotiz
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: associatedContact.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule onUpdateComment={associatedContactUpdate} key='associatedContactCommentsModule' servicename={ENUM_SERVICES.ASSOCIATEDCONTACTCOMMENTS} readonly={readonly} parentId={associatedContact.id} idFieldname='associatedContactId' comments={associatedContact.comments} />
					</div>
				</div>
			</div></>
		)
	}
	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title='Netzwerkkontakt löschen' message={`<span>Wollen Sie den Netzwerkkontakt<br/><b>${associatedContact.name}</b><br/>unwiederruflich löschen?<span>`} labelOk='Ja'
				handleOnClick={deleteAssociatedContact} displayConfirmDialog={displayDeleteConfirmDialog} setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Netzwerkkontakt' name={associatedContact.name} serviceId={associatedContact.id} serviceName={ENUM_SERVICES.ASSOCIATEDCONTACTS} userId={currentUser.id} />
			<EditDialog
				form={<AssociatedContactForm formik={formik} key='associatedContact-form' />}
				formik={formik}
				patchForm={patchAssociatedContact}
				header={'Netzwerkkontakt bearbeiten'}
				message={'Hier können sie die Netzwerkkontakt bearbeiten'}
				setDisplayDialog={setDisplayAssociatedContactDialog}
				displayDialog={displayAssociatedContactDialog}
			/>
		</>
	}

	const renderHeader = () => {
		return <div className={'flex flex-column'}>
			<div className='flex font-bold mb-2'>Adresse</div>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Adresse</div>
			</div>
		</div>
	}

	const renderAddress = () => {
		const { line1, line2, line3, postcode, city, country } = associatedContact
		return <div className='flex flex-column py-2 px-2 overflow-x-auto'>
			<div ><label className='w-full block'>{line1}</label></div>
			<div><label className=' block'>{!line2 ? '' : line2}</label></div>
			<div><label className=' block'>{!line3 ? '' : line3}</label></div>
			<div className="mt-3"><label className=' block'>{postcode + ' / ' + city}</label></div>
			<div ><label className=' block'>{country}</label></div>
		</div>

	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, awnsomeIcon: ENUM_AWESOMEICON.ADDRESS, icon: ENUM_ICONS.ADDRESS + "fa-lg", component:
					<Card className="relatedusersheet card-sheet" header={renderHeader}>
						{renderAddress()}
					</Card>
			},
		]
		return sideSheets;
	}

	return (!associatedContact ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!associatedContact ? '' : renderContent()}{!associatedContact ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={associatedContact.id}
		/>
	)
}