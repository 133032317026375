import { format, parseISO } from "date-fns";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { patchData } from "../feathers";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ConfirmDialog } from "./ConfirmDialog"
import { EAADivider } from "./EAADivider";
import { EditorChangesChecked } from "./EditorChangesChecked";
import { ENUM_ICONS } from "./Icons";

export const emptyComment = { id: null, version: 1, active: 1, comment: '', commentDate: new Date() }

export const CommentsModule = ({ servicename, readonly, comments, onUpdateComment, idFieldname, parentId }) => {
	const currentUser = useCurrentUser();
	const [comment, setComment] = useState({ ...emptyComment });
	const [sortedComments, setSortedComments] = useState([]);
	const [value, setValue] = useState('');
	const [displayConfirmDeleteCommentDialog, setDisplayConfirmDeleteCommentDialog] = useState(false);
	const [displayCommentDialog, setDisplayCommentDialog] = useState(false);

	useEffect(() => {
		if (comments) {
			comments = comments.filter(entry => entry.active);
			const sortedComments = comments.sort((x, y) => { const a = y.commentDate; const b = x.commentDate; return a == b ? 0 : a > b ? 1 : -1; })
			setSortedComments(sortedComments)
		}
		setComment({ ...emptyComment })
	}, [comments])

	const patchComment = async () => {
		if (!comment.id) {
			comment.userId = currentUser.id;
			comment.commentDate = new Date();
		}
		comment[idFieldname] = parentId
		await patchData(servicename, comment).then(async () => {
			setValue('')
			setComment({ ...emptyComment })
			setDisplayCommentDialog(false);
			setDisplayConfirmDeleteCommentDialog(false);
			if (onUpdateComment) {
				onUpdateComment()
			}
		})
	}

	const onDeleteCommentHandler = async (comment) => {
		setComment({ ...comment, commentDate: parseISO(comment.commentDate) });
		setDisplayConfirmDeleteCommentDialog(true);
	}

	const deleteComment = async () => {
		comment.active = 0;
		patchComment()
	}

	const onNewComment = () => {
		setComment({ ...emptyComment })
		setDisplayCommentDialog(true)
	}

	const onEditComment = (comment) => {
		setComment({ ...comment, commentDate: parseISO(comment.commentDate) });
		setValue({ ...comment.comment })
		setDisplayCommentDialog(true);
	}

	const onChangeComment = (value) => {
		setComment({ ...comment, comment: value })
	}

	const renderComments = () => {
		return sortedComments.map((comment) => {
			return (!comment.active ? '' :
				<div className='flex flex-column mr-2' key={'comment_' + comment.id}>
					<div className='flex flex-row w-full gap-3'>
						<div className='flex w-15rem color-text-blue font-medium font-bold'>
							{comment.user.lastname + ', ' + comment.user.firstname}
						</div>
						<div className='flex w-15rem color-lessblack font-medium font-bold'>
							{format(parseISO(comment.commentDate), 'yyyy-MM-dd HH:mm')}
						</div>
						<div className='flex flex-grow-1 justify-content-end pt-1 color-lessblack font-medium gap-3'>
							{currentUser.id !== comment.userId || readonly ? '' : <i className={ENUM_ICONS.PENCIL + "edit-icon"} onClick={() => { onEditComment(comment) }} />}
							{currentUser.id !== comment.userId || readonly ? '' : <i className={ENUM_ICONS.TRASH + "del-icon"} onClick={() => { onDeleteCommentHandler(comment) }} />}
						</div>
					</div>
					<div className='flex flex-row w-full mt-1'>
						<div className='flex-1 text-base color-lessblack editor-changes-checked border-round-sm p-2' dangerouslySetInnerHTML={{ __html: comment.comment }} />

					</div>
				</div>
			)
		})
	}

	return (
		<div className='comments-module'>
			<div className="flex flex-column">
				<div className='flex m-2 text-600 text-base'>
					{(comments ? comments.filter(entry => entry.active).length : 'keine') + ' Kommentare'}
				</div>

				<div className='flex bg-white shadow-2 m-2 w-full overflow-y-auto'>
					<div className='flex flex-column w-full gap-4 m-4 surface-section '>
						<EAADivider className={readonly ? 'hidden' : 'mt-2 '} icon={ENUM_ICONS.PLUS} label='Kommentar anlegen' key='jobopening-dialog' onClickHandler={onNewComment} />
						{comments ? renderComments() : ''}
					</div>
				</div>
			</div>
			<Dialog header={comment.id ? 'Kommentar ändern' : 'Neuen Kommentar hinzufügen'} visible={displayCommentDialog} onHide={() => setDisplayCommentDialog(false)} id="dialog-jobopeningcomment" className='eaa-inputdialog' >
				<div className='flex w-full my-4'>
					<span className="p-float-label w-full">
						<EditorChangesChecked id='comment' value={comment.comment} setValue={(value) => onChangeComment(value)} label='Beschreibung' setShowDialog={() => { }} />
					</span>
				</div>
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCommentDialog(false)} />
					<Button onClick={patchComment} disabled={!comment.comment || comment.comment === ''} className="flex-order-0 ml-3 " label='Speichern' />
				</div>
			</Dialog>
			<ConfirmDialog title='Kommentar entfernen' message={`Sind Sie sicher, Ihren Kommentar vom ${comment.commentDate ? format(comment.commentDate, 'yyyy-MM-dd') : ''}\nzu löschen?'`} labelOk='Ja'
				handleOnClick={deleteComment} displayConfirmDialog={displayConfirmDeleteCommentDialog} setDisplayConfirmDialog={setDisplayConfirmDeleteCommentDialog} />
		</div>
	)

}