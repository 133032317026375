import { LoggerContext, logContext, logDefault } from "./Utils/logger";
import { FindDataError } from "./errorClasses";
import client, { getData } from "./feathers";


export const copyObject = (object) => {
	return JSON.parse(JSON.stringify(object))
}

export const getSortedList = (list, fieldname, descending) => {
	return list.sort((a, b) => {
		let x = a[fieldname];
		let y = b[fieldname];
		if (typeof x === 'string' || x instanceof String) { x = x.toLowerCase() }
		if (typeof y === 'string' || y instanceof String) { y = y.toLowerCase() }
		if (x < y) { return !descending ? -1 : 1 }
		if (x > y) { return !descending ? 1 : -1 }
		return 0;
	})
}

export const fetchDataWithTimeout = async (servicename, setList, seconds) => {
	logDefault("CompanyDataTest", 'info', "CompanyDataTest " + servicename + ' start:')
	setTimeout(async () => {
		getData(servicename).then((result) => {
			setList(result);
			logDefault("CompanyDataTest", 'info', "CompanyDataTest " + servicename + ' end:')
		}).catch((error) => { LoggerContext.error("Error fetchDataWithTimeout service:" + servicename, error) });
	}, seconds);
}

export const fetchDataWithPagination = async (servicename, setList, query) => {
	logContext('fetchDataWithPagination', 'info', '########### ' + servicename + ' getData');
	const service = client.service(servicename);
	let $skip = 0;
	let _list = [];
	let count = 0;
	do {
		count = await service.find({ query: query ? { ...query, $skip, $limit: 50 } : { $skip, $limit: 50 } }).then((result) => {
			_list = _list.concat(result);
			$skip = _list.length
			return result.length;
		}).catch((error) => {
			console.error("Error fetchDataWithPagination service:" + servicename, error)
			throw new FindDataError("Error fetchDataWithPagination service:" + servicename, error, servicename)
		});
	} while (count > 0);
	logContext('fetchDataWithPagination', 'info', `########### ${servicename} fetchDataWithPagination found:${_list.length}`);
	setList(_list);
	return
}