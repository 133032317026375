import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from "react";
import { AddressDialog, handleSaveCompanyAddress } from '../Addresses/AddressDialog';
import { AddressesCard } from "../Addresses/AddressesCard";
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EAADivider } from "../Components/EAADivider";
import { ENUM_ICONS } from '../Components/Icons';
import { deleteData } from "../feathers";
import { logDefault } from '../Utils/logger';

const CLASSNAME = 'CompanyAddresses';
export const CompanyAddresses = ({ company, addresses, numCarousel, hideDivider, onUpdateCompany, readonly }) => {
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [addressId, setAddressId] = useState();
	const [address, setAddress] = useState();

	useEffect(() => {
		//console.log('CompanyAddresses readonly: ' + readonly)
	}, [])

	const deleteAddress = async () => {
		await deleteData('addresses', address.id).then(() => {
			if (onUpdateCompany) {
				onUpdateCompany();
			}
		})
	}

	const onDeleteAddress = (address) => {
		setAddress(address);
		setDisplayConfirmDialog(true);
	}

	const onEditAddress = (address) => {
		logDefault(CLASSNAME, 'info', 'onEditAddress:' + address.id)
		setAddressId(address.id);
		onUpdateCompany(company);
		setDisplayDialog(true);
	}

	const renderAddress = (address) => {
		return !address ? '' : <AddressesCard readonly={readonly} key={'address-card-' + address.id} address={address} onEditHandler={onEditAddress} onDeleteHandler={onDeleteAddress} />
	}

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	const handleSaveAddress = async (addressId) => {
		await handleSaveCompanyAddress(company, addressId);
		setDisplayDialog(false);
		onUpdateCompany();
	}

	return (
		<>
			<ConfirmDialog title='Adresse entfernen' message={`Sind Sie sicher,\nAdresse ${address && address.addressType ? address.addressType.display : ''}\n vom Unternehmen zu entfernen?`} labelOk='Ja'
				handleOnClick={deleteAddress} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<div className="flex flex-wrap w-full">
				<EAADivider className={(hideDivider || readonly ? 'hidden ' : '') + 'm-0 my-3 p-1'} icon={ENUM_ICONS.PLUS} label='Neue Adresse hinzufügen' onClickHandler={(e) => { setAddressId(null); setDisplayDialog(true) }} />
				<Carousel className='w-full mt-10 ' showIndicators={false} value={addresses} numVisible={numCarousel} numScroll={numCarousel} itemTemplate={renderAddress} responsiveOptions={responsiveOptions} />
				<AddressDialog addressId={addressId} displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} handleSave={handleSaveAddress} />
			</div>
		</>
	)
}