import { CompanyForm, emptyCompany, validateCompany } from "./CompanyForm"
import { useEffect } from "react";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { getCompany, patchCompany } from "./CompanyManager";

export const CompanyChangeDialog = ({ companyId, displayCompanyDialog, setDisplayCompanyDialog, handleOnCompanyPatched }) => {

	const currentUser = useCurrentUser();

	useEffect(() => {
		if (companyId) {
			getCompany(companyId).then((company) => {
				formikCompany.setValues(company)
			});
		}
	}, [companyId])

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyCompany)),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (<>
		<EditDialog
			displayDialog={displayCompanyDialog}
			formik={formikCompany}
			labelButtonOk='Ändern'
			header='Unternehmensdetails ändern'
			message={'Hier können Sie die Details des Unternehmens ' + formikCompany.values.name + ' ändern.'}
			patchForm={async () => {
				await patchCompany(formikCompany.values, currentUser).then((company) => {
					handleOnCompanyPatched(company)
				})
			}}
			setDisplayDialog={setDisplayCompanyDialog}
			form={<CompanyForm formik={formikCompany} readOnly={false} />}
		/>
	</>
	)
}