import { endOfDay, format, startOfDay, startOfYear } from "date-fns";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { Calendar } from "primereact/calendar";
import { useState, useRef } from "react";
import client from "../feathers";
import { useCurrentUser, useCurrentUserIsSuperAdmin } from "../Persistence/CurrentUserContext";
import { ToastError } from "../Utils/ToastError";
import { getSortedList } from "../snippets";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";

export const StatisticCaseRequests = () => {
	const currentUser = useCurrentUser();
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const configurationsList = useConfigurationsList()
	const [dateFrom, setDateFrom] = useState(startOfYear(new Date()));
	const [dateTo, setDateTo] = useState(new Date());
	const [toastParams, setToastParams] = useState(null);
	const [data, setData] = useState(null);
	const dt = useRef(null);

	const exportCSV = async () => {
		if (data) {
			const fileName = format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '_Antrags-Statistik_' + format(dateFrom, 'yyyy-MM-dd') + '-bis-' + format(dateTo, 'yyyy-MM-dd') + '.csv';

			const csv = ["Antragsart;Gesamt;" + configurationsList.CaseRequestStatusList.map((status) => status.display)]
			for (let record of data.filter(e => e.id !== 0)) {
				csv.push(record.label + ";" + record.total + ";" + configurationsList.CaseRequestStatusList.map((status) => record[status.alias]).join(';'))
			}
			csv.push(data[0].label + ";" + data[0].total + ";" + configurationsList.CaseRequestStatusList.map((status) => data[0][status.alias]).join(';'))

			var file = new File([csv.join('\n')], fileName, { lastModified: new Date().getTime(), type: "text/csv" });
			let exportUrl = URL.createObjectURL(file);
			var fileLink = document.createElement('a');
			fileLink.href = exportUrl;
			fileLink.download = fileName;
			fileLink.click();
		}
	}

	const onClickCreateStatistic = async (startDate, endDate) => {
		setData(null);
		//alert('Statistic generieren für ' + currentUser.displayname + ' von ' + format(dateFrom, 'yyyy-MM-dd') + ' bis ' + format(dateTo, 'yyyy-MM-dd'))
		await client.service('utils').create({ currentUser, method: 'statisticCaseRequests', timezoneOffsetStart: startDate.getTimezoneOffset(), timezoneOffsetEnd: endDate.getTimezoneOffset(), startDate: startOfDay(startDate), endDate: endOfDay(endDate), printStopwatch: isSuperAdmin }).then(async (result) => {
			let sortedList = getSortedList(result, 'id', false);
			const gesamt = sortedList.shift();
			sortedList = getSortedList(result, 'label', false);
			sortedList.unshift(gesamt)
			setData(sortedList)
		}).catch((error) => {
			setToastParams({ title: 'Bei der Erstellung der Statistik ist ein Fehler aufgesteten !', message: error.message })
		});
	}
	return <div className="card shadow-2 p-4 my-4 bg-white">
		<div className='font-bold mb-4'>Antrags-Statistik</div>
		<div className="p-fluid grid formgrid gap-4">
			<div className="field col-fixed w-15rem">
				<label htmlFor="datePersFrom">Von</label>
				<Calendar maxDate={dateTo} id="datePersFrom" value={dateFrom} onChange={(e) => { setData(null); setDateFrom(e.value) }} showIcon dateFormat="dd.mm.yy" />
			</div>
			<div className="field col-fixed w-15rem">
				<label htmlFor="datePersTo">Bis</label>
				<Calendar minDate={dateFrom} maxDate={new Date()} id="datePersTo" value={dateTo} onChange={(e) => { setData(null); setDateTo(e.value) }} showIcon dateFormat="dd.mm.yy" />
			</div>
		</div>
		<div className='flex'>
			<Button disabled={dateFrom == null || dateTo == null} onClick={() => onClickCreateStatistic(dateFrom, dateTo)} className='flex  text-white' label='Statistik generieren' />
		</div>
		<div className='flex mt-6'>
			{!data ? '' : <DataTable value={data.filter(e => e.id !== 0)} ref={dt}>
				<Column footer={data[0].label} footerClassName='border-top-3' header='Antragsart' field='label'></Column>
				<Column footer={data[0].total} footerClassName='border-top-3' header='Gesamt' field='total' className='font-bold'></Column>
				{configurationsList.CaseRequestStatusList.map((status) => <Column footer={data[0][status.alias]} footerClassName='border-top-3' header={status.display} field={status.alias}></Column>)}
			</DataTable>}
		</div>
		<div className='flex my-2'>
			{!data ? '' : <Button type="button" icon="pi pi-external-link" iconPos="left" label="Als CSV exportieren" onClick={exportCSV}></Button>}
		</div>
		<ToastError toastParams={toastParams} />
	</div>
}
