import React, { useContext, useState, useEffect } from "react";
import { updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, logDefault, LoggerContext } from "../Utils/logger";
import { useCurrentUser } from "./CurrentUserContext";
import { readOverviewData } from "../Overviews/OverviewManager";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'CaseEventsProvider';
const servicename = ENUM_SERVICES.CASEEVENTS;
const CaseEventsContext = React.createContext();
const CaseEventsInitContext = React.createContext();
const CaseEventsCountRecordsContext = React.createContext();

export function useCaseEventsList() {
	return useContext(CaseEventsContext);
}

export function useCaseEventsInit() {
	return useContext(CaseEventsInitContext);
}
export function useCaseEventsCountRecords() {
	return useContext(CaseEventsCountRecordsContext);
}

export function CaseEventsProvider({ children }) {
	const currentUser = useCurrentUser();
	const [list, setList] = useState();
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)
	const [countRecords, setCountRecords] = useState(0);

	const initProvider = () => {
		if (!mounted) {
			setMounted(true)
		}
	}

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', 'ListItemChanged', listItemChanged)
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' useEffect update list entries: ' + (list ? list.length : 0));
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])


	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', '########### ' + providername + ' getData');
			setTimeout(async () => await readOverviewData(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS, 250, setList, setCountRecords), 1)
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted, currentUser])

	return (
		<CaseEventsCountRecordsContext.Provider value={countRecords}>
			<CaseEventsInitContext.Provider value={initProvider}>
				<CaseEventsContext.Provider value={list}>
					{children}
				</CaseEventsContext.Provider>
			</CaseEventsInitContext.Provider>
		</CaseEventsCountRecordsContext.Provider>
	)
}