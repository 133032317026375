import { format, parse } from "date-fns";
import { patchData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import logger from "../Utils/logger";
import { addAssociatedContactId } from "../AssociatedContacts/AssociatedContactsManager";
import { ASSOCIATEDCONTACTCONFIG } from "../AssociatedContacts/AssociatedContactSheet";
import { getSortedList } from "../snippets";


export const patchCaseEvent = async (caseEvent: any, eaaCase: any, setDisplayDialog: Function, handleUpdate: Function) => {
	caseEvent.eaaCaseId = eaaCase.id;
	caseEvent.eventDate = parse(format(caseEvent.eventTime, 'HH:mm'), 'HH:mm', caseEvent.eventDate);
	delete caseEvent.timeInputRef

	/* Wenn neuer case-Request und eventType = CASETRANSFER -> setze status des Falls auf TRANSFERED */
	if (!caseEvent.id && ((caseEvent.eventType.alias || caseEvent.eventType) === 'CASETRANSFER')) {
		eaaCase.status = 'TRANSFERED'
		await patchData(ENUM_SERVICES.EAACASES, eaaCase)
	}

	await patchData(ENUM_SERVICES.CASEEVENTS, caseEvent).then(async (patchedCaseEvent) => {
		if (caseEvent.reminder) {
			const reminder = {
				id: null, description: caseEvent.reminderMessage, date: new Date(), serviceName: ENUM_SERVICES.EAACASES, serviceId: eaaCase.id,
				userId: caseEvent.reminderUser.id, name: eaaCase.name + ' - ' + eaaCase.company.name, reminded: 0
			}
			await patchData(ENUM_SERVICES.REMINDERS, reminder).catch((error) => {
				logger.error("Error  while patch reminder: " + error.message)
			})
		}
		if (['CASETRANSFER', 'CASERECEIVE'].includes((caseEvent.eventType.alias || caseEvent.eventType)) && caseEvent.associatedContactId !== null) {
			if (!eaaCase.associatedContacts.find((e: any) => e.id === caseEvent.associatedContactId)) {
				await addAssociatedContactId(ASSOCIATEDCONTACTCONFIG.CASE, caseEvent.associatedContactId, eaaCase).then((associatedContact) => {
					console.log('eaaCase associatedContact: created', associatedContact)
				})

			}

		}

		if (setDisplayDialog) {
			setDisplayDialog(false)
		}
		if (handleUpdate) {
			handleUpdate(patchedCaseEvent)
		}

	}).catch((error) => {
		logger.error('Fehler beim Speichern des caseEvents:' + error.message)
	})

}

export const renderEventClassifiers = (eventClassifierStructure: any, eventClassifiers: any) => {
	const allItems: any[] = [];
	const eventClassifiersList = eventClassifiers;//eventClassifiers.map(eventClassifier => eventClassifier.caseEventClassifier)

	if (!eventClassifierStructure) {
		return ''
	}
	const classifierStructure = eventClassifierStructure.map((structure: any) => {
		structure.items.forEach((item: any) => allItems.push(item))
		const structureItems = structure.items.map((item: any) => item.caseEventClassifier);
		const items = eventClassifiersList.filter((item: any) => structureItems.includes(item.caseEventClassifier));
		return { ...structure, items }
	}).filter((structure: any) => structure.items.length > 0)
	return classifierStructure
}