import { useState, useEffect } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ROUTES } from '../Navigation/Routes';
import { readOverviewData } from './OverviewManager';
import { LoadingMessageDataView } from '../Components/LoadingMessageDataView';
import { ENUM_ICONS } from '../Components/Icons';
import { useBreadcrumbsUpdate } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useOrganizationsList } from '../Persistence/OrganizationsContext';
import { ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';


const CLASSNAME = 'OverviewRelatedUsersDataview'
const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS);
export const OverviewRelatedUsersDataview = () => {
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const configurationsList = useConfigurationsList();
	const organizationsList = useOrganizationsList();
	const [dataviewList, setDataviewList] = useState([]);
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	const addList = (list) => {
		setDataviewList(dataviewList.concat(list))
	}

	useEffect(() => {
		if (mounted) {
			setTimeout(async () =>
				Promise.all([
					await readOverviewData('OVERVIEWS_EAACASESRELATEDUSERS', 50, addList, setCountRecords),
					await readOverviewData('OVERVIEWS_COMPANIESRELATEDUSERS', 50, addList, setCountRecords),
					await readOverviewData('OVERVIEWS_PROVIDERACTIVITIESRELATEDUSERS', 50, addList, setCountRecords)
				]).then(([list1, list2, list3]) => {
					setDataviewList([].concat(list1, list2, list3))
				})
				, 1)
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])
	const handleOpenRelatedUserObject = async (serviceName, id) => {
		const route = serviceName === 'companies' ? ROUTES['COMPANIES'] : (serviceName === 'eaa-cases' ? ROUTES['EAACASES'] : ROUTES['PROVIDERACTIVITIES'])
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + id }] })
	}
	const renderContextMenu = (rowData) => {
		return <div className={'open-icon flex w-full h-1rem ' + (rowData.hideEdit ? 'justify-content-end' : 'justify-content-between ')}>
			{rowData.hideEdit ? '' : <i className={ENUM_ICONS.EYE} onClick={() => handleOpenRelatedUserObject(rowData.serviceName, rowData.serviceId)} />}
		</div>
	}

	const renderRoleType = (rowData) => {
		const roleType = configurationsList.CaseRoleList.find(e => e.alias === rowData.roleType)
		return roleType ? roleType.display : 'n.n.'
	}
	const renderOrganization = (rowData) => {
		const organization = organizationsList ? organizationsList.find(e => e.id === rowData.relatedUser.organizationId) : undefined;
		return organization ? organization.name : 'n.n.'
	}

	const columns = [
		{ field: "serviceName", sortable: true, filter: "serviceName", header: "Art", style: { width: '110px' }, body: 'renderListIcon' },
		{ field: "displayId", sortable: true, filter: true, header: "ID", style: { width: '150px' }, },
		{ field: "name", sortable: true, filter: "name", header: "Name", style: { flexGrow: 1, flexBasis: '150px' } },
		{ field: "millisecondsDate", sortable: true, filter: true, header: "Datum", style: { width: '180px' }, body: 'renderDate' },
		{ field: "relatedUser.id", sortable: true, filter: true, header: "Fachberater*in", style: { width: '250px' }, body: (rowData) => rowData.relatedUser.displayname },
		{ field: "roleType", sortable: true, filter: true, header: "Rolle", style: { width: '200px' }, body: renderRoleType },
		{ field: "relatedUser.organizationId", sortable: true, filter: true, header: "Träger", style: { flexGrow: 1, flexBasis: '150px' }, body: renderOrganization },
		{ style: { width: '30px' }, body: renderContextMenu },
	]

	return <div className='flex flex-column gap-2'>
		<TemplateDataView
			key='datatable-overviews-relatedUsers'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			dataTableList={dataviewList}
			columns={columns}
			loadingMessage={<LoadingMessageDataView countRecords={countRecords} />}
			dataTableWidth='100%'
			DATAVIEW_CONFIG={DATAVIEW_CONFIG} />
	</div>
}