
import { format, startOfDay, endOfDay } from 'date-fns';
import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from "react"
import client from '../feathers';
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsSuperAdmin } from '../Persistence/CurrentUserContext';
import { StatisticOrg } from './StatisticOrg';
import { StatisticPerson } from './StatisticPerson';
import { UserProvider } from "../Persistence/UserContext"
import { ToastError } from '../Utils/ToastError';
import { StatisticAll } from './StatisticAll';
import { StatisticCaseRequests } from './StatisticCaseRequests';
import { StatisticCasePersons } from './StatisticCasePersons';

export const Statistic = () => {

	const currentUser = useCurrentUser();
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const isAdmin = useCurrentUserIsAdmin();
	const [toastParams, setToastParams] = useState(null);

	const onClickCreateStatistic = async (startDate, endDate, userId, organizationId) => {
		//alert('Statistic generieren für ' + currentUser.displayname + ' von ' + format(dateFrom, 'yyyy-MM-dd') + ' bis ' + format(dateTo, 'yyyy-MM-dd'))
		await client.service('utils').create({ currentUser, method: 'statistic', timezoneOffsetStart: startDate.getTimezoneOffset(), timezoneOffsetEnd: endDate.getTimezoneOffset(), startDate: startOfDay(startDate), endDate: endOfDay(endDate), userId, organizationId, printStopwatch: isSuperAdmin }).then(async (result) => {
			const fileName = format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '_BIH_Statistik.pdf';
			const base64 = await fetch(result);
			var blob = await base64.blob();

			var file = new File([blob], fileName, { lastModified: new Date().getTime(), type: "application/pdf" });
			let exportUrl = URL.createObjectURL(file);
			var fileLink = document.createElement('a');
			fileLink.href = exportUrl;
			fileLink.download = fileName;
			fileLink.click();
		}).catch((error) => {
			setToastParams({ title: 'Bei der Erstellung der Statistik ist ein Fehler aufgesteten !', message: error.message })
		});
	}

	return (
		<div className='mx-auto eaa-dataView ' style={{ maxWidth: 'calc(100vw - 100px)', maxheight: 'calc(100vh - 60px)' }} >
			<TabView className="w-full gap-2">
				<TabPanel header="BIH-Statistik" contentStyle={{ maxheight: 'calc(100vh - 100px)' }}>
					<UserProvider> <StatisticPerson onClickCreateStatistic={onClickCreateStatistic} /></UserProvider>
					{!isAdmin && currentUser.visibilityMode !== 'ALL' ? '' : <StatisticAll onClickCreateStatistic={onClickCreateStatistic} />}
					{!isAdmin && !currentUser.roles.find(e => e.alias === 'statistic-org') ? '' : <StatisticOrg onClickCreateStatistic={onClickCreateStatistic} />}
				</TabPanel>
				{!isAdmin ? '' : <TabPanel header="Antrags-Statistik"><StatisticCaseRequests /></TabPanel>}
				{!isAdmin ? '' : <TabPanel header="Fallpersonen-Statistik"><StatisticCasePersons /></TabPanel>}

			</TabView>
			<ToastError toastParams={toastParams} />

		</div>
	)

}