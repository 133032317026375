import { SplitButton } from "primereact/splitbutton";
import { Divider } from "primereact/divider";
import { useCurrentUser, useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";

export const CaseEventDivider = ({ caseEvents, icon, label, onClickHandler, className }) => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();

	const [disableItems, setDisableItems] = useState();

	useEffect(() => {
		const caseEvent = caseEvents && caseEvents.length > 0 ? caseEvents.find(e => e.eventType.alias === 'CONTACT') : null;
		setDisableItems(!(caseEvent && caseEvent.id))

	}, [caseEvents])

	const items = [
		{ label: 'Ergebnis anlegen', disabled: disableItems, command: () => { onClickHandler('CONTACTRESULT') } },
		{ label: 'Nachhaltigkeit anlegen', disabled: disableItems, command: () => { onClickHandler('SUSTAINABILITY') } },
		{ label: 'Interne Notiz anlegen', disabled: disableItems, command: () => { onClickHandler('INTERN') } },
		{ label: 'Übergabe anlegen', disabled: disableItems, command: () => { onClickHandler('CASETRANSFER') } }
	]

	return (<div className='flex flex-column'>
		<Divider className={"caseevent-divider " + (className ? className : ' h-0rem')} align="center">
			<SplitButton className='hidden m-0 p-0' label={label} icon={icon + " mr-3"} onClick={() => onClickHandler('CONTACT')} model={items} disabled={currentUser.permissions === 'READER'} ></SplitButton>
		</Divider>
		<div className={isReader ? 'hidden' : 'flex flew-row w-full justify-content-between my-4'} id='createbuttons-caseeventsheet'>
			<div className='flex flex-auto '>
				<Button disabled={isReader} className='accordion-tab-event' label='Kontakt anlegen' onClick={() => onClickHandler('CONTACT')} />
				<Button disabled={isReader || disableItems} className='accordion-tab-event' label='Ergebnis anlegen' onClick={() => onClickHandler('CONTACTRESULT')} />
				<Button disabled={isReader || disableItems} className='accordion-tab-event' label='Nachhaltigkeit anlegen' onClick={() => onClickHandler('SUSTAINABILITY')} />
			</div>
			<div className='justify-content-end'>
				<Button disabled={isReader} className='w-12rem accordion-tab-intern' label='Interne Notiz anlegen' onClick={() => onClickHandler('INTERN')} />
				<Button disabled={isReader} className='w-12rem accordion-tab-transfer' label='Übergabe anlegen' onClick={() => onClickHandler('CASETRANSFER')} />
			</div>
		</div>
	</div>
	)
}