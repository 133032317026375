import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList } from "../snippets";

const listenernames = ['created', 'patched'];
const providername = 'CaseRequestTypesProvider';
const servicename = ENUM_SERVICES.CASEREQUESTTYPES;
const CaseRequestTypesContext = React.createContext();
const CaseRequestTypesSelectionContext = React.createContext();

export function useCaseRequestTypesSelectionList() {
	return useContext(CaseRequestTypesSelectionContext);
}

export function useCaseRequestTypesList() {
	return useContext(CaseRequestTypesContext);
}

export function CaseRequestTypesProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, (_list) => setList(getSortedList(_list, 'name', false)), servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', '########### ' + providername + ' update');
			updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
			setListSelection(getSortedList(list, 'name', false).filter((entry) => entry.active))
		}

	}, [list, mounted])

	useEffect(() => {
		if (mounted) {
			getData(servicename).then((_list) => {
				setList(getSortedList(_list, 'name', false));
				const listSelection = _list.filter((entry) => entry.active)
				setListSelection(getSortedList(listSelection, 'name', false));
				logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length} listSelection:${listSelection.length}`)
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.info(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (

		<CaseRequestTypesContext.Provider value={list}>
			<CaseRequestTypesSelectionContext.Provider value={listSelection}>
				{children}
			</CaseRequestTypesSelectionContext.Provider>
		</CaseRequestTypesContext.Provider>
	)
}