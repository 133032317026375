import { useState, useEffect } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { readOverviewData } from './OverviewManager';
import { LoadingMessageDataView } from '../Components/LoadingMessageDataView';
import { DataviewsConfig, ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { v4 as uuidv4 } from 'uuid';

const CLASSNAME = 'OverviewCompanyAddressesDataview'
const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES);
export const OverviewCompanyAddressesDataview = () => {
	const configurationsList = useConfigurationsList()
	const [dataviewList, setDataviewList] = useState();
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => await readOverviewData(DATAVIEW_CONFIG.alias, 50, setDataviewList, setCountRecords), 1)
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const renderAddressType = (rowData) => {
		return !configurationsList || !configurationsList.AddressTypeList ? '' : configurationsList.AddressTypeList.find(e => e.alias === rowData.addressType).display
	}

	const exportFunction = (entry) => {
		const value = {
			'ID': entry.displayId,
			'Adressart': configurationsList.AddressTypeList.find(e => e.alias === entry.addressType).display,
			'Name des Unternehmens': entry.name,
			'Region': entry.region.name,
			'Berater*in': entry.responsible.displayname,
			'Adresse': entry.address.split(' , ').filter(e => e !== '').join('\n'),
			'PLZ': entry.postcode,
			'Stadt': entry.city,
			'Telefonnummer': entry.phone,
			'Mobilnummer': entry.mobile,
			'Faxnummer': entry.fax,
			'E-Mail': entry.mail,
			'Status': entry.active ? 'aktiv' : 'inaktiv'
		}
		return value
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: true, header: "ID", style: { width: '100px' } },
		{ field: "addressType", sortable: true, filter: true, header: "Adressart", style: { width: '130px' }, body: renderAddressType },
		{ field: "name", sortable: true, filter: "name", filterPlaceholder: "Unternehmen suchen", header: "Name des Unternehmens", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "responsible", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { width: '250px' }, body: renderResponsible },
		{ field: "region.name", sortable: true, filter: "region.name", filterPlaceholder: "Region suchen", header: "EAA-Region", style: { width: '300px' }, body: null },
		{ field: "address", sortable: true, filter: true, header: "Adresse", style: { width: '250px' }, body: (rowData) => <div>{rowData.address.split(' , ').map(e => <div key={uuidv4()}>{e}</div>)}</div> },
		{ field: "postcode", sortable: true, filter: "name", header: "PLZ", style: { width: '100px' } },
		{ field: "city", sortable: true, filter: "name", header: "Stadt", style: { width: '200px' } },
		{ field: "active", sortable: true, filter: "active", header: "Status", style: { width: '150px' }, body: 'renderTag' }
	]

	return <div className='w-full overflow-auto'>
		<div className='' >
			<TemplateDataView
				showOpenLinkColumn={true}
				key='datatable-overviews-companyaddresses'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				dataTableList={dataviewList}
				ROUTE={ENUM_ROUTES.COMPANIES}
				columns={columns}
				loadingMessage={<LoadingMessageDataView countRecords={countRecords} />}
				dataTableWidth='100%'
				exportFunction={exportFunction}
				exportColumns={9}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
			/>
		</div>
	</div>
}