import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TemplateDataView } from '../Components/TemplateDataView';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useJobOpeningsList } from '../Persistence/JobOpeningsContext';
import { JobOpeningForm } from './JobOpeningForm';
import { ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';

const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.JOBOPENINGS);

export const JobOpeningDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const jobOpeningsList = useJobOpeningsList();
	const configurationsList = useConfigurationsList();

	const [jobOpening, setJobOpening] = useState();
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();


	const navigate = useNavigate();
	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [configurationsList, navigate])

	const patchJobOpening = async () => {
		await patchData('job-openings', jobOpening, currentUser).then((jobOpening) => {
			const label = jobOpening.company.name + ' | ' + jobOpening.description;
			addBreadcrumb(`${label.length > 50 ? label.substring(0, 49) : label}`, `${ENUM_ROUTES.JOBPROFILES}/` + jobOpening.id);
		})
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", style: { width: '100px' }, body: null },
		{ field: "company.name", sortable: true, filter: "company.name", header: "Unternehmen", style: { flexGrow: 1, flexBasis: '200px' }, body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ field: "location", sortable: true, filter: "location", header: "Adresse", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ field: "description", sortable: true, filter: "description", header: "Stellenbeschreibung", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ field: "jobType", sortable: true, filter: "jobType", header: "Typ", style: { width: '200px' }, body: 'renderList' },
		{ field: "millisecondsOpeningDate", sortable: true, filter: "millisecondsOpeningDate", header: "Startdatum", style: { width: '180px' }, body: 'renderDate' },
		{ field: "status", sortable: true, filter: "status", header: "Status", style: { width: '210px' }, body: 'renderTag' }
	]
	return (
		<div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<Dialog header='Stellenprofil anlegen' visible={displayCompanyDialog} onHide={() => setDisplayCompanyDialog(false)} id="dialog-jobopening-details" className='eaa-inputdialog'>
				<JobOpeningForm editmode={true} setFormHasErrors={setFormHasErrors} setJobOpening={setJobOpening} key={'JobOpeningForm'} setShowDialog={() => { }} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCompanyDialog(false)} />
					<Button onClick={patchJobOpening} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>

			<TemplateDataView
				columns={columns}
				showOpenLinkColumn={true}
				dataTableList={jobOpeningsList}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
				buttons={[<Button
					key='button-new-jobprofile'
					onClick={() => { setDisplayCompanyDialog(true) }}
					disabled={currentUser.permissions === 'READER'}
					className='flex  text-white'
					label='Stellenprofil anlegen' />]}
				ROUTE={ENUM_ROUTES.JOBPROFILES} />

		</div>
	)

}