import { useState, useEffect } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { readOverviewData } from './OverviewManager';
import { LoadingMessageDataView } from '../Components/LoadingMessageDataView';
import { DataviewsConfig, ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { ENUM_ICONS } from '../Components/Icons';


const CLASSNAME = 'OverviewCompanyContactsDataview'
const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS);
export const OverviewCompanyContactsDataview = () => {
	const configurationsList = useConfigurationsList()
	const [dataviewList, setDataviewList] = useState();
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => await readOverviewData(DATAVIEW_CONFIG.alias, 50, setDataviewList, setCountRecords), 1)
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const renderContactType = (rowData) => {
		return !configurationsList || !configurationsList.ContactTypeList ? '' : configurationsList.ContactTypeList.find(e => e.alias === rowData.contactType).dataview
	}
	const renderContactdata = (rowData) => {
		return <div>
			{rowData.phone ? <div><i className={ENUM_ICONS.PHONE} /> {rowData.phone}</div> : ''}
			{rowData.mobile ? <div><i className={ENUM_ICONS.MOBILE} /> {rowData.mobile}</div> : ''}
			{rowData.mail ? <div><i className={ENUM_ICONS.ENVELOPE} /> {rowData.mail}</div> : ''}
		</div>
	}

	const exportFunction = (entry) => {
		const value = {
			'ID': entry.displayId,
			'Name des Unternehmens': entry.name,
			'Kontakt Klassifikation': configurationsList.ContactTypeList.find(e => e.alias === entry.contactType).display,
			'Nachname': entry.lastname,
			'Vorname': entry.firstname,
			'Telefon': entry.phone,
			'Mobile': entry.mobile,
			'E-Mail': entry.mail,
			'Fax': entry.fax,
			'Status': entry.active ? 'aktiv' : 'inaktiv'
		}
		return value
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: true, header: "ID", style: { width: '100px' } },
		{ field: "name", sortable: true, filter: true, filterPlaceholder: "Unternehmen suchen", header: "Name des Unternehmens", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "responsible", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { width: '250px' }, body: renderResponsible },
		{ field: "region.name", sortable: true, filter: "region.name", filterPlaceholder: "Region suchen", header: "EAA-Region", style: { width: '300px' }, body: null },
		{ field: "contactType", sortable: true, filter: true, header: "Kontakt Klassifikation", style: { width: '200px' }, body: renderContactType },
		{ field: "fullname", sortable: true, filter: true, filterPlaceholder: "Name suchen", header: "Name der Ansprechpartner*in", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "contactdata", sortable: true, filter: true, header: "Kontaktdaten", style: { width: '300px' }, body: renderContactdata },
		{ field: "active", sortable: true, filter: "active", header: "Status", style: { width: '150px' }, body: 'renderTag' }
	]

	return <div className='w-full overflow-auto'>
		<div className='' >
			<TemplateDataView
				showOpenLinkColumn={true}
				key='datatable-overviews-companyaddresses'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				dataTableList={dataviewList}
				ROUTE={ENUM_ROUTES.COMPANIES}
				columns={columns}
				loadingMessage={<LoadingMessageDataView countRecords={countRecords} />}
				dataTableWidth='100%'
				exportFunction={exportFunction}
				exportColumns={8}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
			/>
		</div>
	</div>
}