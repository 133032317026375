import feathers from '@feathersjs/client';
import { stripSlashes } from '@feathersjs/commons';
import { reactLocalStorage } from 'reactjs-localstorage';
import io from 'socket.io-client';
import urlIO from './config.js';
import * as Sentry from "@sentry/react";
import { DeleteDataError, FindDataError, GetDataError, PatchDataError } from './errorClasses';
import logger, { logContext, LoggerContext } from './Utils/logger';

const bcrypt = require('bcryptjs');

export const socket = io(urlIO);
const client = feathers();


client.hooks({
	error(context) {
		if (context.path !== 'authentication' || context.method !== 'remove') {
			if (context.error && context.error.name === 'NotAuthenticated') {

			}
			logger.error(`Error in '${context.path}' service method '${context.method}'`, context.error.stack);
		}
	},
	before: {
		all: [(context) => {
			const { path, method, app: { authentication: authService } } = context
			// bypass this for the actual auth service
			if (stripSlashes(authService.options.path) === path && method === 'create') {
				return context
			}
			return context;
		}]
	}
})

client.configure(feathers.socketio(socket, {
	timeout: 30000
}));

client.configure(feathers.authentication({
	storage: window.localStorage,
	handleSocket: socket
}));

export const setupSocketErrorHandling = (errorCallback, successCallback) => {
	socket.removeAllListeners();
	socket.on('connect', successCallback);
	socket.on('error', errorCallback);
	socket.on('connect_error', errorCallback);
	socket.on('connect_failed', errorCallback);
	socket.on('disconnect', errorCallback);
}

export const checkPassword = (user, oldPassword) => {
	return bcrypt.compareSync(oldPassword, user.password); // true
}

export const logout = async (currentUser) => {
	reactLocalStorage.remove('refreshToken');
	reactLocalStorage.remove('userInactiveIntervall');
	reactLocalStorage.remove('expireTimeRefreshToken');
	reactLocalStorage.remove('expireTime');
	logger.debug("############### LOGOUT ###############")
	Sentry.addBreadcrumb({
		category: "auth",
		message: "Logout user " + (currentUser ? currentUser.email : 'undefined'),
		level: "info",
	});
	client.logout();
	return null;
}

export async function updateListEntryInProvider(providername, _listentry, origList, setUpdatedList, servicename, fetchNew) {

	if (!_listentry.refId || _listentry.refId === 0) {
		if (!_listentry) {
			logger.error(providername + " updateListEntryInProvider entry is nothing:");
			return;
		}
		if (fetchNew && _listentry.id) {
			_listentry = await getData(servicename, _listentry.id).catch((error) => {
			});
		}
		//const _origList = origList.splice(0);
		var index = origList.findIndex(e => e.id === _listentry.id);
		logContext('feathers', 'info', providername + ' updateListEntryInProvider: ' + (!_listentry.removed) + ' index = ' + index, _listentry)
		if (index === -1) {
			if (!_listentry.removed) {
				origList.splice(origList.length, 0, _listentry)
			}
		} else if (_listentry.removed) {
			origList.splice(index, 1);
		} else {
			origList.splice(index, 1, _listentry);
		}
		setUpdatedList(origList.splice(0));
		LoggerContext.debug(`${providername} updateListEntryInProvider index: ${index} id:${_listentry.id} listentries:${origList.length}`);
	}
}

export function updateServiceListener(servicename, listenernames, handleListEntryChanged) {
	const service = client.service(servicename);
	listenernames.forEach((listener) => {
		service.removeListener(listener);
		if (handleListEntryChanged !== null) {
			service.on(listener, handleListEntryChanged);
		} else {
			logger.debug('CLEANUP ' + servicename);
		}
	})
}


export const getData = async (path, id, switchedUser) => {
	const service = client.service(path);
	const query = switchedUser ? { switchedUser } : {}
	if (id) {
		return await service.get(id).catch((error) => {
			throw new GetDataError("id=" + id, error, path, id);
		});
	} else {
		return await service.find({ paginate: false }).then((result) => {
			return result.data ? result.data : result;
		}).catch((error) => {
			console.error("Error get Data service:" + path + " id:" + id, error)
			throw new FindDataError("Error get Data service:" + path + " id:" + id, error, path)
		});
	}
}

export const findData = async (path, query) => {
	const service = client.service(path);
	const params = { query, paginate: false };
	return await service.find(params).then((result) => {
		return result.data ? result.data : result;

	}).catch((error) => {
		console.error(error.message, error)
		throw new FindDataError("", error, path)
	}
	);
}

export const deleteData = async (path, id) => {
	const service = client.service(path);
	return await service.remove(id).then((result) => {
		console.debug(`deleteData ${path} result`)
	}).catch((error) => {
		console.error(error.message, error)
		throw new DeleteDataError("", error, path)
	});
}

export const createPassword = (defaultPassword) => {
	var result = '';
	var characters = 'ABCDEFGHKLMNPQRSTUVWXYZ123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < 8; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return (defaultPassword ? 'test' : result);
}

export const patchData = async (path, data, params) => {
	const service = client.service(path);
	/** wenn keine id vorhanden ist, muss ein neuer Datensatz angelegt werden */
	delete data.timeInputRef
	if (!data.id) {
		//console.log('patchDate create:', data)
		return await service.create(data, params).then(async (newData) => {
			/** Datensatz neu holen, weil Assoziationen beim CREATE nicht vorhanden sind */
			return await getData(path, newData.id);
		}).catch((error) => {
			console.error(error.message, error)
			throw new PatchDataError(error.message, error, path, data)
		});
	} else {
		return await service.patch(data.id, data, params).catch((error) => {
			console.error(error.message, error)
			throw new PatchDataError(error.message, error, path, data)
		});
	}
}

export const fetchIconsBase64 = async () => {
	const service = client.service('utils');
	return await service.create({ method: 'iconsBase64' })
}


export default client;