import { format, parseISO } from "date-fns"
import { Accordion, AccordionTab } from "primereact/accordion"
import { useEffect, useState } from "react"
import { CaseEventDivider } from "../Components/CaseEventDivider"
import { ConfirmDialog } from "../Components/ConfirmDialog"
import { ENUM_ICONS } from "../Components/Icons"
import { deleteData } from "../feathers"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext"
import { useCurrentUser } from "../Persistence/CurrentUserContext"
import { UserProvider } from "../Persistence/UserContext"
import logger from "../Utils/logger"
import { CaseEventsForm, emptyCaseEvent, validateCaseEvent } from "./CaseEventsForm"
import { useFormik } from "formik"
import { EditDialog } from "../Components/Dialogs/EditDialog"
import { getSortedList } from "../snippets"
import { patchCaseEvent } from "./CaseEventsManager"

export const CaseEventsSheet = ({ eaaCase, caseEvents, eaaCaseUpdate }) => {
	const currentUser = useCurrentUser();
	const [activeIndex, setActiveIndex] = useState([]);
	const [caseEventToDelete, setCaseEventToDelete] = useState();
	const [displayCaseEventDialog, setDisplayCaseEventDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [eventClassifierStructure, setEventClassifierStructure] = useState([]);
	const [sortEventsAsc, setSortEventsAsc] = useState(true);
	const configurationsList = useConfigurationsList();

	useEffect(() => {
		if (configurationsList.CaseEventClassifierStructure) {
			setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		}
	}, [configurationsList])

	useEffect(() => {
		let counter = 0;
		const tags = caseEvents.map(() => { return counter++ });
		setActiveIndex(tags);
	}, [caseEvents])

	const renderEventClassifiers = (eventClassifiers) => {
		const allItems = [];
		const eventClassifiersList = eventClassifiers;//eventClassifiers.map(eventClassifier => eventClassifier.caseEventClassifier)

		if (!eventClassifierStructure) {
			return ''
		}
		const classifierStructure = eventClassifierStructure.map((structure) => {
			structure.items.forEach((item) => allItems.push(item))
			const structureItems = structure.items.map(item => item.caseEventClassifier);
			const items = eventClassifiersList.filter(item => structureItems.includes(item.caseEventClassifier));
			return { ...structure, items }
		}).filter(structure => structure.items.length > 0)


		return classifierStructure.map((structure) => {
			const items = getSortedList(structure.items, 'caseEventClassifier', false);
			return <div className='flex flex-column' key={'classifier_' + structure.headline}>
				<div className='flex p-0 m-0 text-600 text-base font-semibold'>
					<div>{structure.headline}</div>
				</div>

				<div className='flex flex-column p-1 m-1'>

					{items.map((item) => {
						const structureItem = allItems.find(entry => entry.caseEventClassifier === item.caseEventClassifier)
						const caseEventClassifierOption = configurationsList.CaseEventClassifierList.find(e => e.caseEventClassifier === item.caseEventClassifier);
						return <div className={item.count === 0 || caseEventClassifierOption.hide === 1 ? 'hidden' : 'flex grid'} key={'classifier_' + item.caseEventClassifier}>
							<div className='col-fixed p-1 w-2rem'>{structureItem.fieldType === 'NUMBER' ? item.count : (item.count === 0 ? '' : <i className={ENUM_ICONS.CHECK_SQUARE}></i>)}</div>
							<div className='col p-1'>{structureItem.display} </div>
						</div>

					})}
				</div>
			</div>
		})
	}

	const onEditCaseEvent = (caseEvent) => {
		setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		const editCaseEvent = JSON.parse(JSON.stringify(caseEvent))
		setDisplayCaseEventDialog(true);
		formikCaseEvent.setValues(editCaseEvent);

	}
	const onDeleteCaseEvent = (caseEvent) => {
		setCaseEventToDelete(caseEvent);
		setDisplayConfirmDialogDelete(true);
	}

	const deleteCaseEvent = async () => {

		const id = caseEventToDelete.id
		setCaseEventToDelete(null);
		await deleteData('case-events', id).then(() => {
			eaaCaseUpdate();
			setDisplayConfirmDialogDelete(false);
		}).catch((error) => {
			logger.error('Error while deleteCaseEvent: ' + error.message)
		})

	}

	const handleCreateEvent = (eventType) => {
		setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		const reminderMessage = currentUser.displayname + ' hat Ihnen eine Notiz hinterlassen!'
		const newEvent = { ...emptyCaseEvent, reminderMessage, eventType: configurationsList.EventTypeList.find(entry => entry.alias === eventType), userId: currentUser.id }
		formikCaseEvent.setValues(newEvent)
		setDisplayCaseEventDialog(true)
	}

	const accordionPageHeader = (caseEvent, eventClassifiers) => {

		const eventType = caseEvent.eventType && typeof caseEvent.eventType === 'object' && caseEvent.eventType.constructor === Object ? caseEvent.eventType.acoordionHeader : caseEvent.eventType
		const eventDate = caseEvent.eventDate ? format(parseISO(caseEvent.eventDate), 'dd.MM.yyyy') : '';
		const eventTime = caseEvent.eventDate ? format(parseISO(caseEvent.eventDate), 'HH:mm') : '';

		return <div className="flex w-full justify-content-between m-0">
			<div className='flex w-25rem  gap-4'>
				<div className="flex font-bold w-9rem m-0 ">{`${eventType}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CALENDAR} /> {` ${eventDate}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CLOCK} /> {` ${eventTime}`}</div>
			</div>
			<div className='flex flex-grow-1 '>
				<div className="flex font-bold m-0"> {`Ersteller*in: ${caseEvent.creator ? caseEvent.creator.displayname : ''}`}</div>
			</div>
			<div className="flex m-0 justify-content-end gap-2">
				<i className={currentUser.permissions === 'READER' || eaaCase.status.alias === 'INACTIVE' ? 'hidden' : (ENUM_ICONS.PENCIL + 'fa-lg text-white-500')} onClick={(event) => { onEditCaseEvent(caseEvent); event.stopPropagation(); }} />
				<i className={currentUser.permissions !== 'ADMIN' && caseEvent.creator && caseEvent.creator.id !== currentUser.id ? 'hidden' : (ENUM_ICONS.TRASH + 'fa-lg text-white-500')} onClick={(event) => { onDeleteCaseEvent(caseEvent); event.stopPropagation(); }} />
			</div>
		</div>
	}

	const renderCaseEvent = (caseEvent) => {
		const eventClassifiers = caseEvent.caseEventClassifiers ? caseEvent.caseEventClassifiers : [];
		const eventType = caseEvent.eventType.alias;
		const isTransfer = eventType === 'CASETRANSFER' || eventType === 'CASERECEIVE';
		return <AccordionTab key={'caseEvent_' + caseEvent.id} headerClassName={(eventType === 'INTERN' ? 'accordion-tab-intern' : (isTransfer ? 'accordion-tab-transfer' : '')) + ' w-full'} header={accordionPageHeader(caseEvent, eventClassifiers)} >
			<div className="flex flex-column " >
				<div className='flex flex-column py-2'>
					{!isTransfer ? '' :
						<div className='flex text-600 text-base mb-3'>
							{(eventType === 'CASETRANSFER' ? 'Übergabe des Falls an "' : 'Eingehender Fall von "') + caseEvent.transferOrganization + '" ' + caseEvent.transferUser}
						</div>}
					{!caseEvent.description ? '' : <><div className={'flex text-600 text-base font-semibold ' + (isTransfer ? '' : 'mb-2')}>
						{isTransfer ? 'Nachricht' : 'Beschreibung'}
					</div>
						<div className='editor-changes-checked' dangerouslySetInnerHTML={{ __html: caseEvent.description }} /></>}
				</div>
				{renderEventClassifiers(eventClassifiers)}
			</div>
		</AccordionTab>
	}

	const renderCaseEvents = (caseEvents) => {
		if (caseEvents) {
			getSortedList(caseEvents, 'eventDate', sortEventsAsc)
			//			caseEvents.sort((x, y) => { const a = sortEventsAsc ? x.eventDate : y.eventDate; const b = sortEventsAsc ? y.eventDate : x.eventDate; return a == b ? (y.id > x.id ? 1 : -1) : a > b ? 1 : -1 })
		} else {
			return 'keine'
		}
		const tabs = caseEvents.map((caseEvent) => {
			return renderCaseEvent(caseEvent)
		});

		return <div style={{ width: '100%' }}>
			<CaseEventDivider caseEvents={caseEvents} icon={ENUM_ICONS.PLUS} label='Kontakt anlegen' onClickHandler={handleCreateEvent} className={eaaCase.status.alias !== 'INACTIVE' ? 'my-3' : 'hidden'} />
			<div className='flex justify-content-end mb-2 gap-2 cursor-pointer' onClick={() => { setSortEventsAsc(!sortEventsAsc) }} ><i className="pi pi-sort-alt" /><span>{(!sortEventsAsc ? 'Aufsteigend' : 'Absteigend') + ' nach Datum'}</span></div>
			<Accordion id='accordion-caseeventsheet' multiple className='w-full' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
				{tabs}
			</Accordion>
		</div>
	}

	/** formik CaseEvent */
	const formikCaseEvent = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyCaseEvent)),
		validate: validateCaseEvent,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
		validateOnBlur: true,
	});

	return (
		<>
			<ConfirmDialog title='Kontakt löschen?' message={`<span>Sind Sie sicher, dass Sie <br/><b>${caseEventToDelete ? caseEventToDelete.eventType.deleteMessage : ''} vom ${caseEventToDelete ? format(parseISO(caseEventToDelete.eventDate), 'yyyy-MM-dd HH:mm') : ''}</b><br/> wirklich unwiderruflich löschen wollen?</span>`} labelOk='Ja'
				handleOnClick={deleteCaseEvent} displayConfirmDialog={displayConfirmDialogDelete} setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />

			<EditDialog
				form={<UserProvider><CaseEventsForm formik={formikCaseEvent} caseEventsParent={caseEvents} key='caseEventForm' /></UserProvider>}
				formik={formikCaseEvent}
				header={formikCaseEvent.values.id ? formikCaseEvent.values.eventType.dialogHeader : formikCaseEvent.values.eventType.dialogHeaderNew}
				message=''
				setDisplayDialog={setDisplayCaseEventDialog}
				displayDialog={displayCaseEventDialog}
				patchForm={async () => await patchCaseEvent(formikCaseEvent.values, eaaCase, setDisplayCaseEventDialog, eaaCaseUpdate)}
			/>
			<div className="grid m-0 caseeventssheet select-none" >
				<div className='col-12'></div>
				{renderCaseEvents(caseEvents)}
			</div>
		</>
	)

}