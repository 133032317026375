import React, { useContext, useState, useEffect } from "react";
import client, { findData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { fetchDataWithPagination } from "../snippets";

const UserContext = React.createContext();
const UserSelectionContext = React.createContext();
const UserResponsiblesContext = React.createContext();

const servicename = 'users';
const listenernames = ['created', 'patched'];
const providername = 'UserProvider';

export function useUserList() {
	return useContext(UserContext);
}
export function useUserSelectionList() {
	return useContext(UserSelectionContext);
}

export function useUserResponsibleList() {
	return useContext(UserResponsiblesContext);
}

export const sortUserList = (list) => {
	const sortedList = !list ? [] : list.sort((a, b) => {
		let x = (a.lastname + a.firstname).toLowerCase();
		let y = (b.lastname + b.firstname).toLowerCase();
		if (x < y) { return -1; }
		if (x > y) { return 1; }
		return 0;
	})
	sortedList.forEach(e => {
		e.nameInList = e.lastname + ', ' + e.firstname
	});
	return sortedList;
}

export function UserProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listResponsibles, setListResponsibles] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false);
			const { id } = listItemChanged;
			if (listItemChanged.removed) {
				setListSelection(listSelection.filter((entry) => entry.id !== id))
				setListResponsibles(listResponsibles.filter((entry) => entry.id !== id))
			} else {
				setListSelection(sortUserList(list))
				client.service('utils').create({ method: 'userResponsibles', id }).then((list) => {
					var index = listResponsibles.findIndex(e => e.id === listItemChanged.id);
					logContext(providername, 'info', 'userResponsibles with id:' + id + ' index:' + index + ' found: ' + list.length + ' listResponsibles:' + listResponsibles.length)
					if (index === -1 && list.length === 1) {
						listResponsibles.splice(listResponsibles.length, 0, listItemChanged)
					} else if (list.length === 1) {
						listResponsibles.splice(index, 1, listItemChanged);
					} else if (index !== -1) {
						listResponsibles.splice(index, 1);
					}
					logContext(providername, 'info', 'userResponsibles listResponsibles:' + listResponsibles.length)
					setListResponsibles(sortUserList(listResponsibles));
				}).catch((error) => { LoggerContext.error("Error userResponsibles " + providername, error) });
			}
			setListItemChanged(null);
		}
	}, [listItemChanged, list, listResponsibles, listSelection])

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			client.service('utils').create({ method: 'userSelection' }).then((list) => {
				logContext(providername, 'info', 'userSelection:' + list.length)
				setListSelection(sortUserList(list))
			}).catch((error) => { LoggerContext.error("Error userSelection " + providername, error) });
			client.service('utils').create({ method: 'userResponsibles' }).then((list) => {
				logContext(providername, 'info', 'userResponsibles:' + list.length)
				setListResponsibles(sortUserList(list));
			}).catch((error) => { LoggerContext.error("Error userResponsibles " + providername, error) });
			new Promise(() => { fetchDataWithPagination(ENUM_SERVICES.USERS, setList, { isAdmin: { $ne: 1 } }) })
			/* 			findData(servicename, { isAdmin: { $ne: 1 } }).then((_list) => {
							setList(_list);
							LoggerContext.debug(`########### ${providername} useEffect found:${_list.length}`);
						}).catch((error) => { LoggerContext.error("Error " + providername, error) }); */
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<UserContext.Provider value={list}>
			<UserSelectionContext.Provider value={listSelection}>
				<UserResponsiblesContext.Provider value={listResponsibles}>
					{children}
				</UserResponsiblesContext.Provider>
			</UserSelectionContext.Provider>
		</UserContext.Provider>
	)
}