import { Editor } from "primereact/editor"
import { useEffect } from "react";
import { logDefault } from "../Utils/logger";

const formats = ['bold', 'italic', 'code', 'code-block', 'link', 'underline', 'color', 'list', 'header', 'h1', 'h2'];
const modules = {
	keyboard: {
		bindings: {
			'list autofill': {
				prefix: /^L\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/
			}
		}
	}
}
export const EditorChangesChecked = ({ id, formik, value, setValue, label, className, setShowDialog, disabled, tabIndex, autoFocus, placeholder, required }) => {

	useEffect(() => {
		logDefault('EditorChangesChecked', 'info', 'id:' + id + ' = ' + value)
	}, [value])

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};

	const onTextChange = (e) => {
		setValue(e.htmlValue);
		if (setShowDialog) {
			setShowDialog(true)
		}
	}
	const header = (<>
		<span className="ql-formats">
			<button className="ql-bold" aria-label="Bold"></button>
			<button className="ql-italic" aria-label="Italic"></button>
			<button className="ql-underline" aria-label="Underline"></button>
			<select className="ql-color" defaultValue="black">
				<option value="black" />
				<option value="rgb(230, 0, 0)" />
				<option value="rgb(0, 138, 0)" />
				<option value="rgb(0, 102, 204)" />
			</select>
		</span>
		<span className="ql-formats">
			<button className="ql-list" value='ordered' aria-label="Mummeriert"></button>
			<button className="ql-link" aria-label="Link"></button>
		</span>
		<span className="ql-formats">
			<button className="ql-header" value='1' aria-label="Normal"></button>
			<button className="ql-header" value='2' aria-label="Normal"></button>
		</span>
	</>
	);

	return (
		<div className={label ? 'field col mr-3' : ''}>
			{label ? <label htmlFor={id} className='block'>{label}</label> : ''}
			<Editor modules={modules} formats={formats} placeholder={placeholder ? placeholder : ""} style={(required && !value ? { height: '250px', border: '1px solid #e7a3a3' } : { height: '250px' })} key={'input-' + id} tabIndex={tabIndex} id={id} name={id} disabled={disabled} value={value} onTextChange={onTextChange} autoFocus={autoFocus} className={' w-full ' + className} autoComplete="off" headerTemplate={header} />
			{formik ? getFormErrorMessage(id) : ''}
		</div>
	)

}

