import React, { useContext, useState, useEffect } from "react";
import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import client, { getData } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { reactLocalStorage } from 'reactjs-localstorage';
import { useTaskareasList } from "./TaskareasContext";
import { useRegionsList } from "./RegionsContext";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const providername = 'ConfigurationsProvider';

const ConfigurationsContext = React.createContext();

const IgnoreInStatisticesList = [
	{ icon: ENUM_ICONS.STATISTICS, display: 'Relevant', datatable: 'Relevant', alias: 0 },
	{ icon: ENUM_ICONS.CROSS + ' text-red-600', display: 'Ignorieren', datatable: 'Ignorieren', alias: 1 }
]
const ServiceList = [
	{ icon: ENUM_ICONS.EAACASE, onlyIcon: true, datatable: 'Fall', alias: ENUM_SERVICES.EAACASES, visible: true },
	{ icon: ENUM_ICONS.COMPANY, onlyIcon: true, datatable: 'Unternehmen', alias: ENUM_SERVICES.COMPANIES, visible: true },
	{ awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, onlyIcon: true, datatable: 'Trägeraktivitäten ', alias: ENUM_SERVICES.PROVIDERACTIVITIES, visible: true },
	{ awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, onlyIcon: true, datatable: 'Netzwerkkontakt', alias: ENUM_SERVICES.ASSOCIATEDCONTACTS, visible: true },
	{ icon: ENUM_ICONS.JOBPROFILE, onlyIcon: true, datatable: 'Stellenprofil', alias: ENUM_SERVICES.JOBPROFILES, visible: true },

]
const AssociatedContactsServiceList = [
	{ icon: ENUM_ICONS.EAACASE, onlyIcon: true, datatable: 'Fall', alias: ENUM_SERVICES.EAACASES },
	{ icon: ENUM_ICONS.COMPANY, onlyIcon: true, datatable: 'Unternehmen', alias: ENUM_SERVICES.COMPANIES },
	{ awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, onlyIcon: true, datatable: 'Trägeraktivitäten ', alias: ENUM_SERVICES.PROVIDERACTIVITIES },
]
const StatusActiveList = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', datatable: 'Aktiv', alias: 1, color: 'green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', datatable: 'Inaktiv', alias: 0, color: 'grey' }];

export function useConfigurationsList() {
	return useContext(ConfigurationsContext);
}

export function ConfigurationsProvider({ children }) {
	const taskareasList = useTaskareasList();
	const regionsList = useRegionsList();
	const [enumsList, setConfigurationsList] = useState();

	useEffect(() => {
		if (taskareasList && regionsList) {
			const config = reactLocalStorage.get('OVERVIEWS', '{}')
			client.service('utils').create({ method: 'updateConfig', config }).then(() => {
				getData('configurations').then((_list) => {
					if (reactLocalStorage.get('eaaModules.REQUESTDISTRIBUTION', "1") === "0") {
						_list.eaaModules.REQUESTDISTRIBUTION = false
					} else if (_list.eaaModules.REQUESTDISTRIBUTION) {
						if (_list.eaaModules.REQUESTDISTRIBUTION === "0") {
							_list.eaaModules.REQUESTDISTRIBUTION = false
						} else {
							_list.eaaModules.REQUESTDISTRIBUTION = true
						}

					}
					_list.StatusActiveList = StatusActiveList;
					_list.IgnoreInStatisticesList = IgnoreInStatisticesList;
					_list.ServiceList = ServiceList;
					if (_list.eaaModules.JOBOPENING !== '1') {
						_list.ServiceList = _list.ServiceList.filter(e => e.alias !== ENUM_SERVICES.JOBPROFILES)
					}
					if (_list.eaaModules.ASSOCIATEDCONTACTS !== '1') {
						_list.ServiceList = _list.ServiceList.filter(e => e.alias !== ENUM_SERVICES.ASSOCIATEDCONTACTS)
					}
					_list.AssociatedContactsServiceList = AssociatedContactsServiceList;
					setConfigurationsList({ ..._list, TaskareasList: taskareasList, RegionsList: regionsList });
					logContext(providername, 'info', `########### ${providername} useEffect found:${Object.getOwnPropertyNames(_list).length}`)
				}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			})
		}

	}, [taskareasList, regionsList])

	useEffect(() => {
		const _list = enumsList ? { ...enumsList, TaskareasList: taskareasList } : { TaskareasList: taskareasList }
		logContext(providername, 'info', `########### ${providername} update taskareasList `, (_list.TaskareasList.length))
		setConfigurationsList(_list);
	}, [taskareasList])

	useEffect(() => {
		const _list = enumsList ? { ...enumsList, RegionsList: regionsList } : { RegionsList: regionsList }
		logContext(providername, 'info', `########### ${providername} update regionsList `, (_list.RegionsList.length))
		setConfigurationsList(_list);
	}, [regionsList])

	return (
		<ConfigurationsContext.Provider value={enumsList}>
			{children}
		</ConfigurationsContext.Provider>
	)
}