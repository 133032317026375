import React, { useContext, useEffect, useState } from "react";
import { useCurrentUser } from "./CurrentUserContext";
import { useUserResponsibleList } from './UserContext';
import { logContext } from '../Utils/logger';
import { patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { parseISO } from 'date-fns';
import { DataviewsConfig, ENUM_DATAVIEWS_CONFIG, getDefaultFilter, getDefaultPagination, getDefaultSort } from '../Enums/ENUM_DATAVIEWS_CONFIG'

const CLASSNAME = 'DataViewFilterProvider';

const DataViewMountedContext = React.createContext();
const DataViewFilterContext = React.createContext();
const DataViewSortContext = React.createContext();
const DataViewFilterUpdateContext = React.createContext();
const DataViewSortUpdateContext = React.createContext();
const DataViewPaginationContext = React.createContext();
const DataViewPaginationUpdateContext = React.createContext();

export function useDataViewMounted() {
	return useContext(DataViewMountedContext);
}
export function useDataViewFilter() {
	return useContext(DataViewFilterContext);
}
export function useDataViewFilterUpdate() {
	return useContext(DataViewFilterUpdateContext);
}
export function useDataViewSort() {
	return useContext(DataViewSortContext);
}
export function useDataViewSortUpdate() {
	return useContext(DataViewSortUpdateContext);
}
export function useDataViewPagination() {
	return useContext(DataViewPaginationContext);
}
export function useDataViewPaginationUpdate() {
	return useContext(DataViewPaginationUpdateContext);
}

export function DataViewFilterProvider({ children }) {
	const currentUser = useCurrentUser();
	const userList = useUserResponsibleList();
	const [mounted, setMounted] = useState(false);

	const [dataViewFilter, setDataViewFilter] = useState([]);
	const [dataViewSort, setDataViewSort] = useState([]);
	const [dataViewPagination, setDataViewPagination] = useState([]);

	useEffect(() => {
		setDataViewFilter(getDefaultFilter())
		setDataViewSort(getDefaultSort())
		setDataViewPagination(getDefaultPagination())
	}, [])

	const setDataViewValues = (data, settings) => {
		const settingKeys = Object.keys(settings);
		for (let dataKey of settingKeys) {
			for (let key of Object.keys(settings[dataKey])) {
				const entry = settings[dataKey][key];
				if (entry.instance === 'DATE' && entry.maxDate) {
					settings[dataKey][key].maxDate = parseISO(settings[dataKey][key].maxDate)
				}
				if (entry.instance === 'DATE' && entry.value) {
					settings[dataKey][key].maxDate = parseISO(settings[dataKey][key].value)
				}
			}
		}

		const dataKeys = Object.keys(data);

		for (let dataKey of dataKeys) {
			if (settingKeys.includes(dataKey)) {
				data[dataKey] = settings[dataKey].maxDate ? { ...settings[dataKey], maxDate: new Date() } : settings[dataKey]
			}
		}
	}

	useEffect(() => {
		if (userList && userList.length !== 0 && currentUser && dataViewFilter) {

			if (currentUser.settings && currentUser.settings.dataViewFilter) {
				for (let entry of Object.keys(dataViewFilter)) {
					const filter = dataViewFilter[entry]
					for (let field of Object.keys(filter)) {
						if (currentUser.settings.dataViewFilter[entry] && currentUser.settings.dataViewFilter[entry][field]) {
							dataViewFilter[entry][field].value = currentUser.settings.dataViewFilter[entry][field].value
						}
					}
				}
				for (let entry of Object.keys(DataviewsConfig)) {
					const filter = DataviewsConfig[entry].filters
					const filtername = DataviewsConfig[entry].alias;
					for (let field of Object.keys(filter)) {
						if (currentUser.settings.dataViewFilter[filtername] && currentUser.settings.dataViewFilter[filtername][field]) {
							filter[field].value = currentUser.settings.dataViewFilter[filtername][field].value
						}
					}
					if (currentUser.settings.dataViewSort[filtername]) {
						DataviewsConfig[entry].sort = currentUser.settings.dataViewSort[filtername]
					}
					if (currentUser.settings.dataViewPagination[filtername]) {
						DataviewsConfig[entry].pagination = currentUser.settings.dataViewPagination[filtername]
					}
				}
				//setDataViewValues(dataViewFilter, currentUser.settings.dataViewFilter);
				setDataViewValues(dataViewSort, currentUser.settings.dataViewSort);
				setDataViewValues(dataViewPagination, currentUser.settings.dataViewPagination);
			} else {
				const user = userList.find(e => e.id === currentUser.id);
				dataViewFilter[ENUM_DATAVIEWS_CONFIG.EAACASES]['responsible.id'].value = user ? [user.id] : [];
				patchData(ENUM_SERVICES.USERSETTINGS, { dataViewFilter, dataViewSort, dataViewPagination, settingId: currentUser.id }).then((settings) => {
					currentUser.settings = settings
				})
			}

			setMounted(true);
		}
	}, [currentUser, userList])

	const setFilter = (DATAVIEW_CONFIG_ENTRY, value) => {
		const filter = { ...dataViewFilter }
		filter[DATAVIEW_CONFIG_ENTRY.alias] = value;
		logContext(CLASSNAME, 'info', 'setFilter: ' + DATAVIEW_CONFIG_ENTRY.alias, value);
		patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, dataViewFilter: filter, settingId: currentUser.id });
		setDataViewFilter(filter)
	}
	const setSort = (DATAVIEW_CONFIG_ENTRY, value) => {
		const sortItem = { ...dataViewSort }
		sortItem[DATAVIEW_CONFIG_ENTRY.alias] = value;
		logContext(CLASSNAME, 'info', 'setSort:' + DATAVIEW_CONFIG_ENTRY.alias, value)
		patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, dataViewSort: sortItem, settingId: currentUser.id });
		setDataViewSort(sortItem)
	}

	const setPagination = (DATAVIEW_CONFIG_ENTRY, value) => {
		const pagination = { ...dataViewPagination }
		pagination[DATAVIEW_CONFIG_ENTRY.alias] = value;
		logContext(CLASSNAME, 'info', 'setPagination:' + DATAVIEW_CONFIG_ENTRY.alias, value)
		patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, dataViewPagination: pagination, settingId: currentUser.id });
		setDataViewPagination(pagination)
	}

	return (
		<DataViewMountedContext.Provider value={mounted}>
			<DataViewFilterContext.Provider value={dataViewFilter}>
				<DataViewSortContext.Provider value={dataViewSort}>
					<DataViewPaginationContext.Provider value={dataViewPagination}>
						<DataViewFilterUpdateContext.Provider value={setFilter}>
							<DataViewSortUpdateContext.Provider value={setSort}>
								<DataViewPaginationUpdateContext.Provider value={setPagination}>
									{children}
								</DataViewPaginationUpdateContext.Provider>
							</DataViewSortUpdateContext.Provider>
						</DataViewFilterUpdateContext.Provider>
					</DataViewPaginationContext.Provider>
				</DataViewSortContext.Provider>
			</DataViewFilterContext.Provider>
		</DataViewMountedContext.Provider>
	)
}
