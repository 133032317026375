import { useState, useEffect } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { LoadingMessageDataView } from '../Components/LoadingMessageDataView';
import { ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { readOverviewData } from './OverviewManager';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';

const CLASSNAME = 'OverviewProviderActivitiesDataview'
const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES);
export const OverviewProviderActivitiesDataview = () => {
	const configurationsList = useConfigurationsList()
	const isAdmin = useCurrentUserIsAdmin();
	const [dataviewList, setDataviewList] = useState();
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => await readOverviewData(DATAVIEW_CONFIG.alias, 50, setDataviewList, setCountRecords), 1)
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", style: { width: '100px' }, body: null },
		{ field: "name", sortable: true, filter: true, filterPlaceholder: "Name suchen", header: "Name der Trägeraktivität", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "typeActivity", sortable: true, filter: "typeActivity", filterPlaceholder: "Name suchen", header: "Typ", style: { flexGrow: 1, flexBasis: '200px' }, body: 'renderList' },
		{ field: "responsible", sortable: true, className: (isAdmin ? '' : 'hidden'), sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { flexGrow: 1, flexBasis: '200px' }, body: renderResponsible },
		{ field: "participants", sortable: true, filter: true, header: "Anzahl Teilnehmer", className: 'w-11rem', body: (rowData) => rowData.participants || '' },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Datum der Trägeraktivität", style: { width: '180px' }, body: 'renderDate', },
		{ field: "millisecondsEnddate", sortable: true, filter: "millisecondsEnddate", header: "Wiederkehrend bis", style: { width: '200px' }, body: 'renderDate' }
	]

	return <div className='w-full overflow-auto'>
		<div className='' >
			<TemplateDataView
				showOpenLinkColumn={true}
				key='datatable-overviews-companyaddresses'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				dataTableList={dataviewList}
				ROUTE={ENUM_ROUTES.COMPANIES}
				columns={columns}
				loadingMessage={<LoadingMessageDataView countRecords={countRecords} />}
				dataTableWidth='100%'
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
			/>
		</div>
	</div>
}